import { Avatar as FluentAvatar, AvatarStatusProps, Flex, ShorthandValue } from '@fluentui/react-northstar';
import { AcceptIcon } from '@fluentui/react-icons-northstar';

import { getInitials } from '../../../../helpers/utils';

type AvatarType = {
  email: string;
  isSelected: boolean;
  status: string;
  emailDataTestId?: string;
};

const Avatar = ({ email, isSelected, status, emailDataTestId }: AvatarType): React.ReactElement => {
  const theme: ShorthandValue<AvatarStatusProps> = {
    active: {
      color: 'green',
      icon: <AcceptIcon />,
      title: 'Available',
    },
    inactive: {
      color: 'grey',
      title: 'Offline',
    },
  };

  const weightValue = isSelected ? 700 : 400;

  return (
    <Flex gap="gap.small">
      <FluentAvatar name={getInitials(email)} status={theme[status]} style={{ fontWeight: weightValue }} />
      <span style={{ lineBreak: 'anywhere', fontWeight: weightValue }} data-testid={emailDataTestId}>
        {email}
      </span>
    </Flex>
  );
};

export default Avatar;
