import { baseUrl } from 'authConfig';
import { getHeadersObject } from 'helpers/utils';
import { ExportReportType } from 'types';
import { customFetch } from './customFetch';

export const exportLogs = async (token?: string): Promise<boolean> => {
  const res = await customFetch<boolean>(`${baseUrl}commondata/ExportLogs`, {
    method: 'POST',
    headers: getHeadersObject(token),
  });
  try {
    return res;
  } catch (_) {
    throw new Error('Failed to export logs');
  }
};

export const generateReport = async (
  token?: string,
  hours = 1000,
  reportType: ExportReportType = 'user_update',
): Promise<Blob> => {
  try {
    const res = await customFetch<Blob>(`${baseUrl}commondata/GenerateReport`, {
      method: 'POST',
      headers: getHeadersObject(token),
      body: JSON.stringify({ hours, reportType }),
      skipParse: true,
      responseType: 'blob',
    });
    return res;
  } catch (_) {
    throw new Error('Failed to generate logs');
  }
};
