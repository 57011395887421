/* eslint-disable @typescript-eslint/no-explicit-any */
import { baseUrl } from 'authConfig';
import { DEFAULT_PRIMARY } from 'helpers/themes';
import { getHeadersObject, getTeamsToken, getTenant } from 'helpers/utils';
import { BatchUpdateResult, ConfigChanges, TenantTheme } from 'types';
import { customFetch } from './customFetch';
import { TenantSettings } from 'types/tenants';

export const resetTenant = async (): Promise<{ success: boolean }> => {
  const token = getTeamsToken();
  const res = await customFetch<{ success: boolean }>(`${baseUrl}powershell/ResetSetup`, {
    method: 'POST',
    headers: getHeadersObject(token),
  });
  return res;
};

export const checkConfigDifferences = async (): Promise<ConfigChanges> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        voiceRoutes: true,
        dialPlans: true,
        pstns: false,
        domain: false,
      });
    }, 5000);
  });
};

export const downloadTenantData = async (): Promise<string> => {
  const token = getTeamsToken();
  const res = await customFetch<string>(`${baseUrl}powershell/getTenantData`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};

export const getDownloadTenantStatus = async (jobId: string): Promise<{ status: number; blob?: Blob }> => {
  const token = getTeamsToken();
  const res = await customFetch<BatchUpdateResult<any>>(`${baseUrl}job/GetJobStatus?jobId=${jobId}`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  if (res.status === 0) return { status: 0 };
  if (res.status === 1) {
    const tenantData = res?.results[0]?.data ?? {};
    const blob = new Blob([JSON.stringify(tenantData, null, 2)], { type: 'application/json' });
    return { status: 1, blob };
  }
  throw new Error('Failed to download tenant data');
};

export const getTenantData = async (): Promise<any> => {
  const token = getTeamsToken();
  const tenant = getTenant();
  const res = await customFetch<any>(`${baseUrl}commondata/gettenant?id=${tenant}`, {
    method: 'GET',
    timeout: 2000,
    headers: getHeadersObject(token),
  });
  return res;
};

export const getDomainTheme = async (subdomain?: string): Promise<TenantTheme> => {
  const token = getTeamsToken();
  try {
    const res = await customFetch<TenantTheme>(`${baseUrl}commondata/getDomainTheme?subdomain=${subdomain}`, {
      method: 'GET',
      timeout: 2000,
      headers: getHeadersObject(token),
    });
    return res;
  } catch (e) {
    return { primaryColor: DEFAULT_PRIMARY };
  }
};

export const getOrganizations = async (): Promise<any> => {
  const token = getTeamsToken();
  if (!token) throw new Error('Invalid session token');
  const res = await customFetch<any>(`${baseUrl}commondata/getorganizations`, {
    method: 'GET',
    headers: getHeadersObject(token),
    retries: 3,
  });
  if (res && res.length > 0) {
    res.forEach((org: { key: any; id: any }) => {
      org.key = org.id;
    });
  }
  return res;
};

export const getTenantSettings = async (tenantId: string): Promise<TenantSettings> => {
  const token = getTeamsToken();
  if (!token) throw new Error('Invalid session token');
  const res = await customFetch<TenantSettings>(`${baseUrl}tenants/${tenantId}/service-principal-settings`, {
    method: 'GET',
    headers: getHeadersObject(token),
    retries: 0,
  });
  return res;
};

export const updateTenantSettings = async (tenantId: string, req: TenantSettings): Promise<TenantSettings> => {
  const token = getTeamsToken();
  if (!token) throw new Error('Invalid session token');
  const res = await customFetch<TenantSettings>(`${baseUrl}tenants/${tenantId}/service-principal-settings`, {
    method: 'POST',
    headers: getHeadersObject(token),
    body: JSON.stringify(req),
    retries: 0,
  });
  return res;
};
