export const alertStyles = {
  margin: '5px 0 10px',
};

export const buttonStyles = {
  margin: 0,
  padding: 0,
  justifyContent: 'flex-start',
};

export const formStyles = {
  margin: 0,
};

export const labelStyles = {
  fontSize: 16,
  margin: '8px 0',
};
