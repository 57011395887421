import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetchBaseQuery } from './customFetch';

export const numberApi = createApi({
  reducerPath: 'numberApi',
  tagTypes: ['Number'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    GetNumbers: builder.mutation({
      query: ({ token }) => ({
        url: 'commondata/getnumbers',
        headers: getHeaders(token),
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'Number', id: 'LIST' }],
    }),
  }),
});

export const { useGetNumbersMutation } = numberApi;
