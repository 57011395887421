/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, ShorthandCollection } from '@fluentui/react-northstar';
import { dropdownProps } from '../constants';
import { DropdownItemType } from '../../../../types';

type ManageDropdownType = {
  disabled?: boolean;
  id?: string;
  items: ShorthandCollection<DropdownItemProps> | DropdownItemType[];
  onChange?: (event: React.MouseEvent | React.KeyboardEvent | null, data: DropdownProps) => void;
  placeholder: string;
  value?: string | null;
  className?: string;
};

const ManageDropdown = ({
  className,
  disabled,
  id,
  items,
  onChange,
  placeholder,
  value,
}: ManageDropdownType): React.ReactElement => {
  const sortedItems = items;
  const [searchQuery, setSearchQuery] = useState(value ?? '');

  useEffect(() => {
    setSearchQuery(value ?? '');
  }, [value]);

  const handleSearchQueryChanged = (_: any, data: DropdownProps) => {
    setSearchQuery(data.searchQuery ?? '');
  };

  const handleItemDescriptionSelect = (item: any) => {
    return item.description;
  };

  return (
    <Dropdown
      {...dropdownProps}
      className={`${className} ${disabled ? '' : 'is-clickable'}`}
      clearable={value && !disabled ? true : false}
      id={id}
      disabled={disabled}
      search
      searchQuery={searchQuery}
      itemToString={handleItemDescriptionSelect}
      itemToValue={handleItemDescriptionSelect}
      items={sortedItems}
      onChange={onChange}
      placeholder={placeholder}
      onSearchQueryChange={handleSearchQueryChanged}
      value={searchQuery}
    />
  );
};

export default ManageDropdown;
