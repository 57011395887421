import { Fragment } from 'react';
import { Avatar, Flex, Header, Text } from '@fluentui/react-northstar';

import { getInitials } from '../../../../helpers/utils';

import { detailStyles, headerStyles, textStyles, wrapperStyles } from './PopoverInfo.styles';

type PopoverInfoType = {
  email: string;
  typeLabel: string | React.ReactElement;
  contact: string;
};

const PopoverInfo = ({ email, typeLabel: TypeLabel, contact }: PopoverInfoType): React.ReactElement => {
  const statusProps = {
    color: 'green',
    title: 'Available',
  };

  return (
    <div style={wrapperStyles}>
      <Flex gap="gap.small">
        <Flex.Item size="size.small">
          <Avatar size="large" name={getInitials(email)} status={statusProps} />
        </Flex.Item>
        <Flex.Item>
          <Flex column gap="gap.small" vAlign="stretch">
            <Header as="h4" content={email} style={headerStyles} />
            <Text content={<small style={textStyles}>Available</small>} />
          </Flex>
        </Flex.Item>
      </Flex>
      <br />
      <Flex gap="gap.small">
        <Flex.Item>
          <Flex column gap="gap.small" vAlign="stretch">
            <Header as="h5" content="User Information" style={detailStyles} />
            <Text
              content={
                <Fragment>
                  <span>Identity Type: </span>
                  {TypeLabel}
                </Fragment>
              }
              style={detailStyles}
            />
            {contact && <Text content={`Phone Number: ${contact}`} style={detailStyles} />}
          </Flex>
        </Flex.Item>
      </Flex>
    </div>
  );
};

export default PopoverInfo;
