import { CallerId } from 'types';

export const HEADER = {
  key: 'header',
  items: [
    {
      content: 'Caller ID Policy',
      key: 'callerIdPolicy',
    },
    {
      content: 'Company Name',
      key: 'companyName',
    },
    {
      content: 'Resource Account',
      key: 'resourceAccount',
    },
    {
      content: 'Actions',
      key: 'actions',
    },
  ],
};

export const DEFAULT_FORM_VALUES: CallerId = {
  upn: { userPrincipalName: '' },
  callerIdPolicy: '',
  companyName: '',
};
