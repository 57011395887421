import { ChevronEndIcon, ChevronStartIcon } from '@fluentui/react-icons-northstar';
import { Button } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';

import { useAppContext } from 'contexts/AppContext';
import './Carousel.css';

export const CarouselItem = ({ children, width }: { children?: React.ReactNode; width?: string }) => {
  const { appTheme } = useAppContext();
  return (
    <div className="carousel-item" style={{ width, backgroundColor: appTheme?.siteVariables?.colors?.brand?.[600] }}>
      {children}
    </div>
  );
};

const Carousel = ({ children, isButtonsVisible }: { children: JSX.Element[]; isButtonsVisible: boolean }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div className="carousel" onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
      <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {React.Children.map(children, (child: JSX.Element, index: number) => {
          return React.cloneElement(child, { width: '100%' });
        })}
      </div>
      <div className="indicators" style={{ display: !isButtonsVisible ? 'none' : 'block' }}>
        <Button
          icon={<ChevronStartIcon />}
          iconOnly
          size="small"
          title="previous"
          text
          onClick={() => updateIndex(activeIndex - 1)}
        />
        <Button
          icon={<ChevronEndIcon />}
          iconOnly
          size="small"
          title="next"
          text
          onClick={() => updateIndex(activeIndex + 1)}
        />
      </div>
    </div>
  );
};

export default Carousel;
