import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, Method, ResponseType } from 'axios';
import { ApiError, ApiResponse } from 'types';

type CustomFetchOpts = {
  method?: Method;
  timeout?: number;
  retries?: number;
  headers?: {
    [key: string]: string;
  };
  body?: string;
  responseType?: ResponseType;
  skipParse?: boolean;
};

const transformHeaders = (headers: Headers) => {
  const res: { [key: string]: string } = {};
  headers.forEach((value, key) => {
    res[key] = value;
  });
  return res;
};

export const customFetchBaseQuery =
  <T>(
    { baseUrl }: { baseUrl?: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
      body?: T;
      headers?: Headers;
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, headers }) => {
    try {
      const tHeaders = transformHeaders(headers || new Headers());
      const res = await axios(`${baseUrl}${url}`, {
        method,
        headers: tHeaders,
        data: body,
      });
      const response = res.data;
      if (!response.result) {
        throw new ApiError({
          data: response?.responseException?.exceptionMessage ?? `Failed to fetch ${url}`,
          status: res.status,
        });
      }
      const result = response.result as T;
      return { data: result };
    } catch (err: unknown) {
      const error = err as AxiosError<{ responseException: { exception: { type: string; message: string } } }>;
      return {
        error: {
          status: error?.response?.status || 500,
          data: error?.response?.data?.responseException?.exception ?? { message: error?.message },
        },
      };
    }
  };

export const customFetch = async <T>(url: string, opts: CustomFetchOpts = {}): Promise<T> => {
  let data = null;
  if (opts?.body) {
    data = JSON.parse(opts.body);
  }
  let retries = opts?.retries ?? 0;
  for (;;) {
    try {
      const res = await axios(url, {
        method: opts?.method,
        headers: opts?.headers,
        timeout: opts?.timeout,
        data,
        responseType: opts?.responseType,
      });
      if (opts?.skipParse) return res.data;
      const response = res?.data as ApiResponse<T>;
      if (!response?.result) {
        throw new ApiError({
          data: {
            message: response?.responseException?.exceptionMessage ?? `Failed to fetch ${url}`,
            type: 'UnhandledError',
          },
          status: res.status,
        });
      }
      const result = response.result;
      return result;
    } catch (err: unknown) {
      const error = err as ApiError;
      console.error(error?.data || error.message);
      if (retries > 0) {
        retries--;
        await new Promise((f) => setTimeout(f, 1000));
      } else {
        throw err;
      }
    }
  }
};
