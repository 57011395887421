import { useHistory } from 'react-router-dom';
import { Flex, Button, Toolbar as FluentToolbar } from '@fluentui/react-northstar';
import { AddIcon, ChevronStartIcon, UndoIcon } from '@fluentui/react-icons-northstar';

import { ManageCallerIdToolbarType } from 'types';

const Toolbar = ({
  loading,
  handleRefreshClick,
  setIsDetailsOpen,
  setSelectedCallerId,
}: ManageCallerIdToolbarType): React.ReactElement => {
  const history = useHistory();

  const handleAddClick = () => {
    setSelectedCallerId(undefined);
    setIsDetailsOpen(true);
  };

  return (
    <>
      <Flex.Item>
        <Button
          content="Caller ID"
          icon={<ChevronStartIcon />}
          onClick={() => history.push('/tab')}
          text
          data-testid="btn-back"
        />
      </Flex.Item>
      <Flex.Item push>
        <section>
          <FluentToolbar
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'end',
            }}
            aria-label="Default"
            items={[
              {
                icon: (
                  <Button
                    content="Create New"
                    icon={<AddIcon />}
                    onClick={handleAddClick}
                    data-testid="btn-create"
                    disabled={loading}
                    text
                  />
                ),
                key: 'create',
              },
              {
                key: 'divider-3',
                kind: 'divider',
              },
              {
                icon: (
                  <Button
                    content="Refresh"
                    icon={<UndoIcon />}
                    onClick={handleRefreshClick}
                    data-testid="btn-refresh"
                    disabled={loading}
                    text
                  />
                ),
                key: 'refresh',
              },
            ]}
          />
        </section>
      </Flex.Item>
    </>
  );
};

export default Toolbar;
