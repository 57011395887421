/* eslint-disable @typescript-eslint/no-explicit-any */
import { CallerId, CallerIdBackendType, CallerIdList, CallerIdRequestType, CallerIdResponse, UpnType } from 'types';
import { baseUrl } from 'authConfig';
import { customFetch } from './customFetch';
import { getHeadersObject } from 'helpers/utils';

export const getCallerIds = async (args: CallerIdRequestType): Promise<CallerIdList> => {
  const res = await customFetch<CallerIdResponse>(`${baseUrl}powershell/GetPaginatedCallerIdPolicies`, {
    method: 'POST',
    headers: getHeadersObject(args.token),
    body: JSON.stringify({ page: args.page, limit: args.limit }),
  });
  try {
    const mappedData = res?.data?.map((s: CallerIdBackendType) => ({
      upn: { userPrincipalName: s.resourceAccount },
      callerIdPolicy: s.identity,
      companyName: s.companyName,
    })) as CallerId[];
    return { ...res, data: mappedData };
  } catch (_) {
    throw new Error('Failed to get caller Ids');
  }
};

export const createCallerId = async (payload: CallerId, token?: string): Promise<boolean> => {
  try {
    const req: CallerId = {
      ...payload,
      upn: (payload.upn as UpnType)?.identity?.toString(),
    };
    const res = await customFetch<boolean>(`${baseUrl}powershell/CreateCallerIdPolicy`, {
      method: 'POST',
      body: JSON.stringify(req),
      headers: getHeadersObject(token),
    });
    return res == null ? false : res;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updateCallerId = async (payload: CallerId, token?: string): Promise<boolean> => {
  try {
    const req: CallerId = {
      ...payload,
      upn: (payload.upn as UpnType)?.identity?.toString(),
    };
    const res = await customFetch<boolean>(`${baseUrl}powershell/UpdateCallerIdPolicy`, {
      method: 'POST',
      body: JSON.stringify(req),
      headers: getHeadersObject(token),
    });
    return res == null ? false : res;
  } catch (err: any) {
    throw new Error(err);
  }
};
