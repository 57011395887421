import { useContext } from 'react';
import { Flex, FlexItem, Text, Button, Popup } from '@fluentui/react-northstar';
import { ErrorIcon, SyncIcon } from '@fluentui/react-icons-northstar';
import { AuthContext } from 'contexts/AuthContext';
import { AuthorizeType } from 'types';

const Authorize = ({ continueMsg, errorMsg, errorSubMsg, showPermission }: AuthorizeType): React.ReactElement => {
  const { acquirePermissions } = useContext(AuthContext);

  const popUpPermission = async () => {
    if (acquirePermissions) {
      await acquirePermissions();
    }
  };

  return (
    <Flex.Item grow align="center">
      <Flex column hAlign="center">
        <Flex vAlign="center" style={{ marginTop: 35 }}>
          {errorMsg && (
            <>
              <Popup
                content={errorSubMsg}
                on="hover"
                trigger={<ErrorIcon size="large" style={{ marginRight: 16 }} />}
              />
              <FlexItem className="app-onboarding-tenant-error">
                <Text error content={errorMsg} />
              </FlexItem>
            </>
          )}
          {continueMsg !== '' && (
            <>
              <SyncIcon style={{ marginRight: 10 }} />
              <FlexItem>
                <Text color="pink" content={continueMsg} temporary />
              </FlexItem>
            </>
          )}
        </Flex>
        <Flex vAlign="center" hAlign="center" style={{ marginTop: 20, marginBottom: 10 }}>
          {showPermission && continueMsg === '' && <Button primary content="Authorize" onClick={popUpPermission} />}
        </Flex>
      </Flex>
    </Flex.Item>
  );
};

export default Authorize;
