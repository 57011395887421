import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { getTenantData } from 'redux/services/tenantsApi';
import { useQuery } from './useQuery';

const useGetTenant = () => {
  const { clearSession } = useContext(AuthContext);

  const { data, loading, refetch } = useQuery(
    () => getTenantData(),
    false,
    false,
    (err) => {
      if (err?.response?.status === 401) {
        clearSession();
      }
    },
  );

  return {
    data,
    loading,
    refetch,
  };
};

export default useGetTenant;
