/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, Flex, FlexItem, Header, Loader } from '@fluentui/react-northstar';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetcountriesMutation } from 'redux/services/countryApi';
import {
  useCheckDomainCapabilityMutation,
  useGetOnboardingStatusMutation,
  useOnboardingPstnMutation,
  useOnboardingTenantDialPlanMutation,
  useOnboardingVoiceRouteMutation,
  useOnboardingactivatedomainMutation,
  useOnboardingdomainMutation,
  useRetrySetupMutation,
} from 'redux/services/onBoardingApi';
import { useInterval } from 'hooks/useInterval';
import { AppContext } from 'contexts/AppContext';
import { deepRefresh } from 'helpers/utils';
import { BatchUpdateResult, JobStatus, OnboardingStatus, OnboardingStep, RtkSuccessResponseType } from 'types';
import { INCREMENT_TIME, ONBOARDING_STEPS, TIMEOUT_SECONDS } from '../../../constants';
import ErrorBox from './ErrorBox';
import Step1 from './contents/Step1';
import Step2 from './contents/Step2';
import Step3 from './contents/Step3';
import Step4 from './contents/Step4';
import Step5 from './contents/Step5';
import Step6 from './contents/Step6';
import Stepper from 'components/common/Stepper';
import { useDownloadTenantData } from 'hooks/useDownloadTenantData';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const DirectRoutingSetup = (): React.ReactElement => {
  const history = useHistory();

  const { token } = useSelector((state: RootState) => state.auth);
  const { onBoardingProgress, refreshTenantData } = useContext(AppContext);

  const [checkDomain, { data: domainData, isLoading: isCheckDomainLoading }] = useCheckDomainCapabilityMutation();
  const [getCountries, { data: countries, isLoading: isCountriesLoading }] = useGetcountriesMutation();
  const [onboardDomain, { data: domain, isLoading: isDomainLoading }] = useOnboardingdomainMutation();
  const [onboardActivateDomain, { data: activateDomain, isLoading: isActivateDomainLoading }] =
    useOnboardingactivatedomainMutation();
  const [onboardPstn, { data: pstn, isLoading: isPstnLoading }] = useOnboardingPstnMutation();
  const [onboardVoiceRoute, { data: voiceRoute, isLoading: isVrLoading }] = useOnboardingVoiceRouteMutation();
  const [onboardDialPlan, { data: dialPlan, isLoading: isDpLoading }] = useOnboardingTenantDialPlanMutation();
  const [getStatus, { data: statusData }] = useGetOnboardingStatusMutation();
  const [retrySetupDomain, { isLoading: isRetrySetupLoading }] = useRetrySetupMutation();
  const { downloadTenantData, isLoading: isDownloadingTenantData } = useDownloadTenantData();

  const [currentCount, setCurrentCount] = useState(0);
  const [domainName, setDomainName] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [progressContent, setProgressContent] = useState('');
  const [progressValue, setProgressValue] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [timerValue, setTimerValue] = useState(0);
  const [withError, setWithError] = useState(false);

  const isOnBoardingLoading = isDomainLoading || isActivateDomainLoading || isPstnLoading || isVrLoading || isDpLoading;

  const isOnStep1 = currentStep === OnboardingStep.SELECT_COUNTRY;
  const isOnStep2 = currentStep === OnboardingStep.ADD_FQDN;
  const isOnStep3 = currentStep === OnboardingStep.ACTIVATE_FQDN;
  const isOnStep4 = currentStep === OnboardingStep.SETUP_PSTN;
  const isOnStep5 = currentStep === OnboardingStep.ADD_VOICE_ROUTES;
  const isOnStep6 = currentStep === OnboardingStep.ADD_DIAL_PLANS;

  useEffect(() => {
    if (!token) return;

    if (onBoardingProgress && onBoardingProgress.onboardingStep !== OnboardingStep.NONE) {
      const { id: jobId, status, onboardingStep, error, countryCodes } = onBoardingProgress;

      setSelectedCountry(countryCodes);
      setCurrentStep(1);

      if (!error) {
        if (status === JobStatus.FAILED) {
          setCurrentStep(onboardingStep);
        }

        if (status === JobStatus.IN_PROGRESS) {
          setCurrentStep(onboardingStep);
          getStatus({ jobId, token }).then((result) => {
            const res = result as { data: BatchUpdateResult<OnboardingStatus> };
            if (res.data) {
              setResultCount(res.data.results.length);
            }
          });
        }

        if (status === JobStatus.COMPLETED) {
          if (onboardingStep === OnboardingStep.ADD_FQDN) {
            setCurrentStep(onboardingStep);
          } else if (onboardingStep === OnboardingStep.ADD_DIAL_PLANS) {
            setIsCompleted(true);
            setCurrentStep(onboardingStep);
          } else {
            setCurrentStep(onboardingStep + 1);
          }
        }
      } else {
        setWithError(true);
        setErrorMessage(error);
        setCurrentStep(onboardingStep);
      }
    }
  }, [getStatus, onBoardingProgress]);

  useEffect(() => {
    if (isCompleted) return;

    switch (currentStep) {
      case OnboardingStep.SELECT_COUNTRY:
        getCountries({ token });
        break;
      case OnboardingStep.ADD_FQDN:
        onboardDomain({ token, body: selectedCountry });
        break;
      case OnboardingStep.ACTIVATE_FQDN:
        onboardActivateDomain({ token, body: selectedCountry });
        break;
      case OnboardingStep.SETUP_PSTN:
        onboardPstn({ token, body: selectedCountry });
        break;
      case OnboardingStep.ADD_VOICE_ROUTES:
        onboardVoiceRoute({ token, body: selectedCountry });
        break;
      case OnboardingStep.ADD_DIAL_PLANS:
        onboardDialPlan({ token, body: selectedCountry });
        break;
      case -1:
        retrySetupDomain({ token }).then(() => {
          setCurrentStep(1);
        });
        break;
      default:
    }
  }, [
    currentStep,
    getCountries,
    isCompleted,
    onboardActivateDomain,
    onboardDialPlan,
    onboardDomain,
    onboardPstn,
    onboardVoiceRoute,
  ]);

  useEffect(() => {
    if (isOnBoardingLoading) {
      setProgressContent('loading...');
    }
  }, [isOnBoardingLoading]);

  useEffect(() => {
    if (timerValue === TIMEOUT_SECONDS) {
      setTimeLeft(30);
    }
  }, [timerValue]);

  useEffect(() => {
    if (timeLeft === 0) {
      deepRefresh();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useInterval(() => {
    if (isCompleted) return;

    let jobId;

    switch (currentStep) {
      case OnboardingStep.SELECT_COUNTRY:
        if (!countries) getCountries({ token });
        break;
      case OnboardingStep.ADD_FQDN:
        jobId = domain?.id;
        break;
      case OnboardingStep.ACTIVATE_FQDN:
        jobId = activateDomain?.id;
        break;
      case OnboardingStep.SETUP_PSTN:
        jobId = pstn?.id;
        break;
      case OnboardingStep.ADD_VOICE_ROUTES:
        jobId = voiceRoute?.id;
        break;
      case OnboardingStep.ADD_DIAL_PLANS:
        jobId = dialPlan?.id;
        break;
      default:
    }

    setStepperContent(jobId);
    setTimerInfo();
  }, 3500);

  const isProgressCompleted = progressValue === 100;
  const isInProgress = !withError && !isProgressCompleted;

  const setStepperContent = (jobId: string) => {
    const { id, results, status } = statusData || {};

    if (jobId && isInProgress) {
      const totalCount = results?.length;

      if (totalCount) {
        const { name, onboardingRequestType } = results[currentCount].data;
        const mainContent = `${onboardingRequestType} - ${name}`;

        if (results[currentCount] && jobId === id) {
          let updatedCount = currentCount;

          if (results[currentCount].status === JobStatus.COMPLETED || status === JobStatus.COMPLETED) {
            updatedCount = currentCount + 1;
            setCurrentCount(updatedCount);
            setProgressValue(Math.round((updatedCount / totalCount) * 100));
          }

          setProgressContent(`${mainContent}... (${updatedCount} of ${totalCount} completed)`);

          if (results[currentCount].status === JobStatus.FAILED || status === JobStatus.FAILED) {
            setWithError(true);
            setErrorMessage(results[currentCount].errors?.[0]?.message);
          }
        }
      }

      getStatus({ jobId, token }).then((result) => {
        const data = result as RtkSuccessResponseType<BatchUpdateResult<OnboardingStatus>>;
        if (data) {
          setResultCount(data.data.results.length);
        }
      });
    }

    if (isProgressCompleted) {
      if (currentStep < OnboardingStep.ADD_DIAL_PLANS && currentStep > OnboardingStep.ADD_FQDN) resetStepperContent();

      if (currentStep === OnboardingStep.ADD_DIAL_PLANS) setIsCompleted(true);

      if (currentStep === OnboardingStep.ADD_FQDN) {
        if (!domainName) {
          setDomainName(domain?.results[0].data.name);
        } else {
          if (domainData?.capabilities?.includes('OfficeCommunicationsOnline')) {
            resetStepperContent();
            setIsNotVerified(false);
            setWithError(false);
          } else {
            setIsNotVerified(true);
            if (domainData?.error?.includes('This domain does not exist. Check the name and try again.')) {
              setWithError(true);
              setErrorMessage(
                'Domain not found. This may cause by an error during the creation of domain or got deleted manually in admin dashboard. Please press the button below to recreate the domain and continue with the manual process.',
              );
            }
          }
        }
      }
    }
  };

  const setTimerInfo = () => {
    if (isInProgress) {
      const stepProgressValue = Math.round(resultCount > 0 ? ((currentCount + 1) / resultCount) * 100 : 0);
      const increasedProgressValue = progressValue + INCREMENT_TIME;

      let currentProgressValue = stepProgressValue;

      if (increasedProgressValue < stepProgressValue) currentProgressValue = increasedProgressValue;

      if (progressValue === currentProgressValue) setTimerValue(timerValue + INCREMENT_TIME);
      else setTimerValue(0);
    } else {
      if (!isProgressCompleted) setTimerValue(timerValue + INCREMENT_TIME);
    }
  };

  const resetState = () => {
    setProgressValue(0);
    setCurrentCount(0);
  };

  const resetError = () => {
    setErrorMessage('');
    setWithError(false);
  };

  const handleNextClick = () => {
    let step = currentStep;
    step++;

    if (step > 0 && step <= 6) {
      setCurrentStep(step);
    }
  };

  const handleFqdnProcess = () => {
    checkDomain({ token, domain: domainName });
  };

  const handleRetryProcess = () => {
    if (!withError) return;

    resetError();
    switch (currentStep) {
      case OnboardingStep.SELECT_COUNTRY:
        getCountries({ token });
        break;
      case OnboardingStep.ADD_FQDN:
        onboardDomain({ token, body: selectedCountry });
        break;
      case OnboardingStep.ACTIVATE_FQDN:
        onboardActivateDomain({ token, body: selectedCountry });
        break;
      case OnboardingStep.SETUP_PSTN:
        onboardPstn({ token, body: selectedCountry });
        break;
      case OnboardingStep.ADD_VOICE_ROUTES:
        onboardVoiceRoute({ token, body: selectedCountry });
        break;
      case OnboardingStep.ADD_DIAL_PLANS:
        onboardDialPlan({ token, body: selectedCountry });
        break;
      case -1:
        retrySetupDomain({ token }).then(() => {
          setCurrentStep(1);
        });
        break;
      default:
    }
    resetState();
  };

  const resetStepperContent = () => {
    const step = currentStep + 1;
    setCurrentStep(step);
    resetState();
  };

  const countriesArray = countries?.map((country: { code: string }) => country.code);

  const errorBoxProps = {
    errorMessage,
    isNotVerified,
    timeLeft,
    withError,
  };

  const stepProps = {
    progressContent,
    progressValue,
    withError,
  };

  const step1Props = {
    countriesArray,
    setSelectedCountry,
  };

  const step6Props = {
    ...stepProps,
    isCompleted,
  };

  return (
    <>
      <Box className="stepper-container-horizontal">
        {isOnStep1 && (
          <Alert
            info
            dismissible
            content={
              <span>
                <strong>IMPORTANT: </strong>
                Please do not exit this application during the initial setup
              </span>
            }
            style={{ marginBottom: 20 }}
          />
        )}
        <Header as="h2" content="Initial Setup" style={{ marginBottom: 20 }} />
        <Flex gap="gap.large" style={{ marginBottom: 20 }}>
          <Stepper
            direction="vertical"
            currentStepNumber={currentStep - 1}
            steps={ONBOARDING_STEPS}
            stepColor="#6264a7"
          />
          <FlexItem>
            <Box style={{ width: '100%' }}>
              {isOnBoardingLoading ? (
                <Loader style={{ marginTop: 70 }} />
              ) : (
                <>
                  <ErrorBox {...errorBoxProps} />
                  {isOnStep1 && <Step1 {...step1Props} />}
                  {isOnStep2 && <Step2 {...stepProps} />}
                  {isOnStep3 && <Step3 {...stepProps} />}
                  {isOnStep4 && <Step4 {...stepProps} />}
                  {isOnStep5 && <Step5 {...stepProps} />}
                  {isOnStep6 && <Step6 {...step6Props} />}
                </>
              )}
            </Box>
          </FlexItem>
        </Flex>
      </Box>
      <Box className="buttons-container">
        {isOnStep1 && (
          <Button
            disabled={selectedCountry?.length === 0 || isDomainLoading}
            onClick={handleNextClick}
            icon={isCountriesLoading && <Loader size="smallest" />}
            content="Submit"
            primary
          />
        )}
        {isOnStep2 && isProgressCompleted && !withError && (
          <Button
            disabled={isCheckDomainLoading}
            onClick={handleFqdnProcess}
            icon={isCheckDomainLoading && <Loader size="smallest" />}
            content="Proceed"
            primary
          />
        )}
        {isOnStep6 && isCompleted && !withError && (
          <div className="buttons-container">
            <Button
              onClick={() => {
                refreshTenantData();
                history.push('/tab');
              }}
              content="Go to application"
              primary
            />
          </div>
        )}
        {!isCompleted && withError && (
          <Flex hAlign="center">
            <FlexItem>
              <>
                <Button
                  onClick={handleRetryProcess}
                  disabled={isCountriesLoading || isCheckDomainLoading || isRetrySetupLoading}
                  icon={
                    (isCountriesLoading || isCheckDomainLoading || isRetrySetupLoading) && <Loader size="smallest" />
                  }
                  content="Retry"
                  primary
                />
                <Button
                  onClick={() => downloadTenantData()}
                  disabled={isDownloadingTenantData}
                  icon={isDownloadingTenantData && <Loader size="smallest" />}
                  content="Download logs"
                  secondary
                />
              </>
            </FlexItem>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default DirectRoutingSetup;
