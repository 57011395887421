import { LockIcon, SettingsIcon } from '@fluentui/react-icons-northstar';
import { Button, Flex, Popup, Text } from '@fluentui/react-northstar';
import { useAppContext } from 'contexts/AppContext';
import { AuthContext } from 'contexts/AuthContext';
import LogoIcon from 'images/logo-icon.png';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '../Avatar';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const APP_NAME = process.env.REACT_APP_INSTANCE_NAME ?? 'Symbio';

const AppHeader = (): React.ReactElement => {
  const { tenantTheme, appTheme } = useAppContext();
  const { accountInfo } = useSelector((state: RootState) => state.auth);
  const { logout } = useContext(AuthContext);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const history = useHistory();

  const redirectSettings = () => {
    setIsUserMenuOpen(false);
    history.push('/setting');
  };

  return (
    <div style={{ padding: 16, background: appTheme?.siteVariables?.colors?.brand['base'] }}>
      <Flex space="between">
        <Flex vAlign="center">
          <img src={tenantTheme?.logoUrl || LogoIcon} alt="Symbio" width={32} style={{ marginRight: 16 }} />
          <Text content={tenantTheme?.appName || APP_NAME} size="larger" style={{ color: '#fff' }} />
        </Flex>
        {accountInfo && (
          <Popup
            open={isUserMenuOpen}
            content={
              <>
                <Button content="Onboarding" icon={<SettingsIcon />} onClick={redirectSettings} text />
                <br />
                <Button content="Logout" icon={<LockIcon />} onClick={logout} data-testid="btn-logout" text />
              </>
            }
            trigger={
              <Avatar
                onClick={() => setIsUserMenuOpen((prev) => !prev)}
                email={accountInfo.username}
                status="active"
                showEmail={false}
              />
            }
          />
        )}
      </Flex>
    </div>
  );
};

export default AppHeader;
