export const actionToolbarStyles = {
  padding: '10px 0 0',
};

export const outlineStyles = {
  marginLeft: 5,
};

export const toolbarStyles: React.CSSProperties = {
  flexDirection: 'row',
  justifyContent: 'end',
  marginBottom: '10px',
};
