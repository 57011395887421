import { AppInstance, ProgressType } from 'types';

export const APP_INSTANCE: AppInstance = (process.env.REACT_APP_INSTANCE_ENV as AppInstance) ?? 'general';

export const PERMISSIONS_ERROR_MESSAGE =
  'We need an administrator to consent to use the application. Please retry pressing the authorize button below. If this  issue persists, please contact Symbio support.';

export const INITIAL_PROGRESS: ProgressType = {
  step1: 'pending',
  step2: 'pending',
};
