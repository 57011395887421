/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { mergeThemes, teamsTheme, ThemePrepared } from '@fluentui/react-northstar';
import { getTheme } from 'helpers/themes';
import useGetTenant from 'hooks/useGetTenant';
import { useQuery } from 'hooks/useQuery';
import { useTeamsFx } from 'hooks/useTeamsFx';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDomainTheme } from 'redux/services/tenantsApi';
import { authSlice } from 'redux/slices/auth';
import { ProgressDataType, TenantTheme } from 'types';

type AppState = {
  isInTeams?: boolean;
  teamsTheme: string;
  onBoardingProgress?: ProgressDataType;
  isPermissionsCheckComplete?: boolean;
  isInitialized?: boolean;
  isTenantLoading?: boolean;
  tenantTheme?: TenantTheme;
  appTheme?: ThemePrepared<any>;
  refreshTenantData: () => void;
  togglePermissionsCheckComplete?: (val?: boolean) => void;
  updateOnboardingProgress?: (payload: ProgressDataType) => void;
};

const AppContext = React.createContext<AppState>({
  isInTeams: undefined,
  teamsTheme: 'default',
  isPermissionsCheckComplete: false,
  refreshTenantData: () => {},
});

const AppContextProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { isInTeams, theme, error, loading, themeString } = useTeamsFx();
  const [isInitialized, setIsInitialized] = useState(false);
  const { data: tenantTheme, loading: isThemeLoading } = useQuery(() => getDomainTheme(window?.location?.hostname));
  const { data: tenantData, loading: isTenantLoading, refetch } = useGetTenant();

  const [onBoardingProgress, setOnBoardingProgress] = useState<ProgressDataType>();
  const [isPermissionsCheckComplete, setIsPermissionsCheckComplete] = useState(false);

  const appTheme = useMemo(() => {
    if (!isInitialized) return undefined;
    const currentTheme = theme || teamsTheme;
    if (!tenantTheme) return currentTheme;
    return !isInTeams
      ? mergeThemes(
          currentTheme,
          getTheme({ primary: tenantTheme.primaryColor, secondary: tenantTheme.secondaryColor }),
        )
      : currentTheme;
  }, [isInTeams, theme, tenantTheme, isInitialized]);

  const togglePermissionsCheckComplete = (val?: boolean) => {
    if (val == null) {
      setIsPermissionsCheckComplete((prev) => !prev);
      return;
    }
    setIsPermissionsCheckComplete(val);
  };

  const updateOnboardingProgress = (payload: ProgressDataType) => {
    setOnBoardingProgress((prev) => ({ ...prev, ...payload }));
  };

  const refreshTenantData = () => {
    refetch();
  };

  useEffect(() => {
    if (isThemeLoading || isTenantLoading) return;
    setIsInitialized(true);
  }, [isThemeLoading, isTenantLoading]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!tenantData) return;
    dispatch(authSlice.actions.setTenantData(tenantData));
  }, [tenantData]);

  if (error || loading || isInTeams == null) return null;
  if (isThemeLoading || isTenantLoading || !tenantTheme) return null;

  return (
    <AppContext.Provider
      value={{
        isInTeams,
        teamsTheme: themeString,
        isPermissionsCheckComplete,
        onBoardingProgress,
        isInitialized,
        tenantTheme,
        appTheme,
        isTenantLoading,
        refreshTenantData,
        updateOnboardingProgress,
        togglePermissionsCheckComplete,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => React.useContext(AppContext);

export { AppContext, AppContextProvider, useAppContext };
