export const actionToolbarStyles = {
  padding: '10px 0 0',
};

export const outlineStyles = {
  marginLeft: 5,
};

export const publishToolbarStyles = {
  borderBottom: '1px solid #ebebeb',
};
