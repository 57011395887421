import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders, getHeadersObject, getTeamsToken, getTenant } from 'helpers/utils';
import {
  OnboardingProcess,
  OnboardingStatus,
  OnboardingStatusRequest,
  BatchUpdateResult,
  PostAuthenticationCheck,
} from 'types';
import { baseUrl } from 'authConfig';
import { customFetch, customFetchBaseQuery } from './customFetch';

export const onBoardingApi = createApi({
  reducerPath: 'onBoardingApi',
  tagTypes: ['OnBoarding'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    onboardingPstn: builder.mutation({
      query: ({ body, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/OnboardingPstn',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    onboardingdomain: builder.mutation({
      query: ({ body, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/onboardingdomain',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    onboardingactivatedomain: builder.mutation({
      query: ({ body, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/onboardingactivatedomain',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    onboardingVoiceRoute: builder.mutation({
      query: ({ body, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/OnboardingVoiceRoute',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    onboardingTenantDialPlan: builder.mutation({
      query: ({ body, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/OnboardingTenantDialPlan',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    OnboardingCallingIdentity: builder.mutation({
      query: ({ token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/OnboardingCallingIdentity',
          method: 'POST',
          headers,
          body: {},
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    getOnboardingStatus: builder.mutation<BatchUpdateResult<OnboardingStatus>, OnboardingStatusRequest>({
      query: ({ jobId, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: `onboarding/GetOnboardingStatus?jobId=${jobId}`,
          method: 'GET',
          headers,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    getOnboardingProgress: builder.mutation({
      query: ({ token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'onboarding/GetOnboardingProgress',
          method: 'GET',
          headers,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    checkDomainCapability: builder.mutation({
      query: ({ domain, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: `powershell/CheckDomainCapability?domain=${domain}`,
          method: 'GET',
          headers,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
    retrySetup: builder.mutation({
      query: ({ token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'powershell/retrySetup',
          method: 'POST',
          headers,
        };
      },
      invalidatesTags: () => [{ type: 'OnBoarding', id: 'LIST' }],
    }),
  }),
});

export const {
  useOnboardingdomainMutation,
  useOnboardingactivatedomainMutation,
  useGetOnboardingStatusMutation,
  useGetOnboardingProgressMutation,
  useOnboardingPstnMutation,
  useOnboardingTenantDialPlanMutation,
  useOnboardingCallingIdentityMutation,
  useOnboardingVoiceRouteMutation,
  useCheckDomainCapabilityMutation,
  useRetrySetupMutation,
} = onBoardingApi;

export const checkOnboardingProcess = async (step: number): Promise<OnboardingProcess> => {
  const token = getTeamsToken();
  const tenant = getTenant();
  if (!token) throw new Error('Invalid session token');
  if (!tenant) throw new Error('Invalid tenant');
  const response = await customFetch<OnboardingProcess>(
    baseUrl + `onboarding/process?step=${step}&tenant_id=${tenant}`,
    {
      method: 'GET',
      headers: getHeadersObject(token),
    },
  );
  return response;
};

export const postAuthenticationCheck = async (): Promise<PostAuthenticationCheck> => {
  const token = getTeamsToken();
  if (!token) throw new Error('Invalid session token');
  const response = await customFetch<PostAuthenticationCheck>(`${baseUrl}onboarding/PostAuthenticationCheck`, {
    method: 'POST',
    headers: getHeadersObject(token),
  });
  return response;
};
