import { ThemeInput } from '@fluentui/react-northstar';
import { hexToLch, lchToHex } from 'lch-color-utils';

type ThemeType = {
  primary?: string;
  secondary?: string;
};

type ThemeColorScheme = {
  brand: { [key: string]: string };
  secondary?: { [key: string]: string };
};

export const DEFAULT_PRIMARY = '#182857';
const COLOR_SCALES = [
  { label: '50', value: 98 },
  { label: '100', value: 95 },
  { label: '200', value: 89 },
  { label: '300', value: 79 },
  { label: '400', value: 69 },
  { label: '500', value: 59 },
  { label: '600', value: 49 },
  { label: '700', value: 39 },
  { label: '800', value: 29 },
  { label: '900', value: 19 },
  { label: '1000', value: 9 },
];

const generateColorRange = (color: string) => {
  const lch = hexToLch(color);
  const result: { [key: string]: string } = {
    base: color,
  };
  for (let i = 0; i < COLOR_SCALES.length; i++) {
    const newLch = { ...lch, l: COLOR_SCALES[i].value };
    result[`${COLOR_SCALES[i].label}`] = lchToHex(newLch).value;
  }
  return result;
};

const getColorScheme = (colors: { [key: string]: string }) => {
  return {
    foreground: colors['base'],
    background: colors['base'],
    border: '#E1DFDD',
    foregroundHover: colors['base'],
    backgroundHover: colors['1000'],
    borderHover: colors['900'],
    shadowHover: '#000',
    foregroundActive: colors['base'],
    backgroundActive: colors['base'],
    borderActive: '#E1DFDD',
    foregroundFocus: colors['base'],
    backgroundFocus: colors['base'],
    borderFocus: '#000',
    foregroundPressed: colors['800'],
    backgroundPressed: colors['800'],
    borderPressed: colors['900'],
    foregroundDisabled: '#C8C6C4',
    backgroundDisabled: '#EDEBE9',
    borderDisabled: '#EDEBE9',
    foreground1: colors['base'],
    foreground2: colors['1000'],
    foreground3: colors['400'],
    foreground4: '#fff',
    foreground5: '#fff',
    background1: '#E5E5F1',
    background2: '#33344A',
    background3: '#373644',
    background4: colors['800'],
    background5: '#E5E5F1',
    background6: colors['base'],
    border1: colors['300'],
    border2: colors['900'],
    foregroundHover1: '#fff',
    foregroundHover2: colors['300'],
    backgroundHover1: '#F4F4FC',
    backgroundHover2: '#E5E5F1',
    foregroundPressed1: '#fff',
    foregroundActive1: colors['base'],
    foregroundActive2: colors['300'],
    backgroundActive1: colors['base'],
    borderActive1: colors['300'],
    borderActive2: colors['900'],
    foregroundFocus1: colors['base'],
    foregroundFocus2: colors['1000'],
    foregroundFocus3: colors['300'],
    foregroundFocus4: '#fff',
    backgroundFocus1: '#E5E5F1',
    backgroundFocus2: '#33344A',
    backgroundFocus3: '#373644',
    borderFocusWithin: '#fff',
    borderFocus1: colors['base'],
    foregroundDisabled1: '#C8C6C4',
    backgroundDisabled1: '#EDEBE9',
  };
};

export const getTheme = (opts: ThemeType): ThemeInput => {
  const colors = generateColorRange(opts.primary || DEFAULT_PRIMARY);
  const secondaryColor = opts.secondary ? generateColorRange(opts.secondary) : undefined;
  const colorScheme: ThemeColorScheme = { brand: getColorScheme(colors) };
  if (secondaryColor) {
    colorScheme.secondary = getColorScheme(secondaryColor);
  }
  return {
    siteVariables: {
      colorScheme,
      colors: {
        brand: colors,
      },
    },
  };
};
