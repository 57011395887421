import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetchBaseQuery } from './customFetch';

export const identityApi = createApi({
  reducerPath: 'identityApi',
  tagTypes: ['Identity'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getIndentities: builder.mutation({
      query: ({ token, body }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'powershell/getpaginatedgraphusers',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Identity', id: 'LIST' }],
    }),
  }),
});

export const { useGetIndentitiesMutation } = identityApi;
