import { Alert, Box } from '@fluentui/react-northstar';
import { CliErrorBoxtype } from 'types';

const ErrorBox = ({ error }: CliErrorBoxtype): React.ReactElement => (
  <Box style={{ marginTop: 8, marginBottom: 8 }}>
    <Alert dismissible visible={!!error} danger content={error} />
  </Box>
);

export default ErrorBox;
