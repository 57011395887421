import {
  Flex,
  FlexItem,
  ListItemHeader,
  Text,
  ListItemEndMedia,
  Label,
  ShorthandCollection,
  ListItemProps,
} from '@fluentui/react-northstar';
import { CallIcon, ChevronEndIcon } from '@fluentui/react-icons-northstar';
import { User } from 'types';

const ListData = (identities: { data: User[] }, onClick: (item: User) => void): ShorthandCollection<ListItemProps> => {
  if (!identities?.data?.length) return [];

  const spread = [...identities.data];

  const chev = (
    <ListItemEndMedia className="always-show">
      <ChevronEndIcon />
    </ListItemEndMedia>
  );

  const buildHeader = (data: User) => {
    return (
      <>
        <ListItemHeader>
          <Text content={data.userPrincipalName} />
          <br />
          <Flex gap="gap.smaller">
            <Label
              circular
              color={data.interpretedUserType === 'User' ? 'brand' : 'green'}
              content={data.interpretedUserType}
            />
            <FlexItem>
              <>{data.voiceLicenseStatus !== 0 && <CallIcon outline />}</>
            </FlexItem>
          </Flex>
          <small style={{ marginTop: 6, display: 'block' }}>{data.onPremLineURI}</small>
        </ListItemHeader>
      </>
    );
  };

  return spread.reduce((collection: ShorthandCollection<ListItemProps>, data: User) => {
    return [
      ...collection,
      {
        key: data.identity,
        header: buildHeader(data),
        endMedia: chev,
        onClick: () => onClick(data),
      },
    ];
  }, []);
};

export default ListData;
