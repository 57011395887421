export const detailStyles = {
  margin: '.2rem 0',
  fontSize: '13px',
};

export const headerStyles = {
  margin: '.25rem 0',
};

export const textStyles = {
  fontSize: '12px',
};

export const wrapperStyles = {
  padding: '.8rem',
};
