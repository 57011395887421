import { Box, Header, Loader } from '@fluentui/react-northstar';
import { ContentType } from 'types';

import ProgressBar from 'components/common/ProgressBar';
import { useAppContext } from 'contexts/AppContext';

const Content = ({ content, header, progressValue, subContent, withError }: ContentType): React.ReactElement => {
  const { appTheme } = useAppContext();
  const progressBarColor = withError ? '#ebebeb' : appTheme?.siteVariables?.colors?.brand?.['base'];

  return (
    <Box style={{ width: '100%' }}>
      <Header as="h3" content={header} style={{ margin: 0 }} />
      {progressValue > 0 ? (
        <>
          <h4 style={{ marginTop: 30 }}>{content}</h4>
          <ProgressBar bgcolor={progressBarColor} color="white" progress={progressValue} height={30} />
        </>
      ) : (
        !withError && <Loader color="brand" style={{ margin: '48.5px 0' }} />
      )}
      <div style={{ marginTop: 35 }}>{subContent}</div>
    </Box>
  );
};

export default Content;
