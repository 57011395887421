import { CommonStepType } from 'types';
import Content from './Content';

const SubContent = () => (
  <>
    <p>Voice Routes and Voice Routing Policies let Microsoft know how and where to send your users calls.</p>
    <p>
      Voice Routing Policies can also be used to restrict calls for individual users such as disallowing international
      calls.
    </p>
  </>
);

const Step3 = ({ progressContent, progressValue, withError }: CommonStepType): React.ReactElement => {
  const commonProps = {
    progressValue,
    withError,
  };

  return <Content header="Add Voice Routes" content={progressContent} subContent={<SubContent />} {...commonProps} />;
};

export default Step3;
