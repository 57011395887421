import { getHeadersObject, getTeamsToken } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetch } from './customFetch';
import { UserJobSync } from 'types/userJob';

export const getPendingUserSyncJobs = async (): Promise<UserJobSync[]> => {
  const token = getTeamsToken();
  const res = await customFetch<UserJobSync[]>(`${baseUrl}job/GetPendingUserSyncJobs`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};

export const getUserSyncJobsByIds = async (ids: string[] = []): Promise<UserJobSync[]> => {
  const token = getTeamsToken();
  const query = ids.map((id) => `jobIds=${id}`).join('&');
  const res = await customFetch<UserJobSync[]>(`${baseUrl}job/GetUserSyncJobs?${query}`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};

export const startSyncUsers = async (): Promise<string[]> => {
  const token = getTeamsToken();
  const res = await customFetch<string[]>(`${baseUrl}job/SyncUsers`, {
    method: 'POST',
    headers: getHeadersObject(token),
  });
  return res;
};
