import Carousel, { CarouselItem } from 'components/common/Carousel';
import parse from 'html-react-parser';
import { BannerType } from 'types';

const ManageCarousel = ({ banners }: { banners: BannerType[] }): React.ReactElement => {
  if (!banners?.length) return <></>;

  return (
    <>
      <Carousel isButtonsVisible={banners.length > 1}>
        {banners?.map(({ id, bannerTitle, bannerText }: BannerType) => (
          <CarouselItem key={id}>
            <p>
              <strong>{`${bannerTitle} : `}</strong>
              {parse(bannerText)}
            </p>
          </CarouselItem>
        ))}
      </Carousel>
    </>
  );
};

export default ManageCarousel;
