import { AcceptIcon, CloseIcon, MoreIcon } from '@fluentui/react-icons-northstar';
import { StatusIconType } from 'types';

export const Icon = ({ status }: StatusIconType): React.ReactElement => {
  switch (status) {
    case 'done':
      return <AcceptIcon />;
    case 'failed':
      return <CloseIcon />;
    default:
      return <MoreIcon />;
  }
};

export default Icon;
