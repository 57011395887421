import ErrorInfoDisplay from 'components/common/ErrorInfoDisplay';
import { PERMISSIONS_ERROR_MESSAGE } from '../../../constants';
import { ErrorCodes, ErrorInfo } from 'types';

export const UnknownStepError = (props: ErrorInfo) => (
  <>
    <div>Unknown step.</div>
    <ErrorInfoDisplay tenantId={props.tenantId} errorCode={ErrorCodes.NOT_CONFIGURED} />
  </>
);

export const MissingTenantError = (props: ErrorInfo & { label: string; url: string }) => (
  <>
    <div>
      It appears that you don't have a valid {props.label} subscription.{' '}
      <a target="_blank" href={props.url} rel="noreferrer">
        Please contact support to get started.
      </a>
    </div>
    <ErrorInfoDisplay tenantId={props.tenantId} errorCode={ErrorCodes.MISSING_TENANT} />
  </>
);

export const InvalidPermissionsError = (props: ErrorInfo) => (
  <>
    <div>{PERMISSIONS_ERROR_MESSAGE}</div>
    <ErrorInfoDisplay tenantId={props.tenantId} errorCode={ErrorCodes.MISSING_TENANT} />
  </>
);
