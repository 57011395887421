import { Provider, createComponent } from '@fluentui/react-northstar';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
import { PropsWithChildren } from 'react';

export type PaginationProps = {
  className?: string;
  pageSize: number;
  currentPage: number;
  siblingCount?: number;
  totalCount: number;
  onPageChange: (page: number) => void;
};

type ColorScheme = {
  brand: {
    foreground1: string;
    foreground3: string;
  };
};

type ComponentVariables = {
  color: string;
  backgroundColor: string;
};

const PaginationButton = createComponent({
  displayName: 'PaginationButton',
  render: ({ config, children }) => {
    const { classes } = config;
    return <li className={classes.root}>{children}</li>;
  },
});

const Pagination = (props: PropsWithChildren<PaginationProps>): React.ReactElement => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className, children } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return <></>;
  }

  const onNext = () => {
    const current = parseInt(currentPage?.toString(), 10);
    onPageChange(current + 1);
  };

  const onPrevious = () => {
    const current = parseInt(currentPage?.toString(), 10);
    onPageChange(current - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Provider
      theme={{
        componentVariables: {
          PaginationButton: ({ colorScheme }: { colorScheme: ColorScheme }) => ({
            color: colorScheme.brand.foreground3,
            backgroundColor: colorScheme.brand.foreground1,
          }),
        },
        componentStyles: {
          PaginationButton: {
            root: ({ variables }: { variables: ComponentVariables }) => ({
              '&.selected': {
                backgroundColor: variables.backgroundColor,
                color: variables.color,
              },
            }),
          },
        },
      }}
    >
      <div className="pagination-wrapper">
        <div className="pagination-children">{children}</div>
        <ul className={classnames('pagination-container', className)}>
          <li
            className={classnames('pagination-item', {
              disabled: currentPage === 1,
            })}
            onClick={onPrevious}
          >
            <div className="arrow left" />
          </li>
          {paginationRange?.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return <PaginationButton className="pagination-item dots">&#8230;</PaginationButton>;
            }
            const page = parseInt(pageNumber?.toString(), 10);
            return (
              <li
                className={classnames('pagination-item', {
                  selected: pageNumber === currentPage,
                })}
                onClick={() => onPageChange(page)}
              >
                {pageNumber}
              </li>
            );
          })}
          <li
            className={classnames('pagination-item', {
              disabled: currentPage === lastPage,
            })}
            onClick={onNext}
          >
            <div className="arrow right" />
          </li>
        </ul>
      </div>
    </Provider>
  );
};

export default Pagination;
