/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Dropdown, DropdownProps, Header, Loader } from '@fluentui/react-northstar';
import { SetStateAction, useContext, useEffect } from 'react';
import { AppContext } from 'contexts/AppContext';
import { useSelector } from 'react-redux';
import { useGetcountriesMutation } from 'redux/services/countryApi';
import { RootState } from 'redux/store';

type EventType = React.MouseEvent | React.KeyboardEvent | null;

type Step1Type = {
  setSelectedCountry: (value: SetStateAction<string[]>) => void;
};

const Step1 = ({ setSelectedCountry }: Step1Type): React.ReactElement => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { onBoardingProgress } = useContext(AppContext);
  const [getCountries, { data: countries, isLoading: isCountriesLoading }] = useGetcountriesMutation();

  useEffect(() => {
    getCountries({ token });
  }, []);

  let countriesArray: string[] = countries?.map((country: { code: string }) => country.code);

  const { countryCodes: currentCountryCodes } = onBoardingProgress || {};

  const handleOnChange = (_: EventType, options: DropdownProps) => {
    const val = options?.value as string[];
    if (!val) return;
    setSelectedCountry([...val, ...(currentCountryCodes || [])]);
  };

  if (currentCountryCodes) {
    countriesArray = countriesArray?.filter((code: string) => !currentCountryCodes.includes(code));
  }

  const isLoading = isCountriesLoading;

  return (
    <Box style={{ width: '100%' }}>
      <Header as="h3" content="Select Country" style={{ margin: 0, paddingBottom: 15 }} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Dropdown
            multiple
            fluid
            disabled={isLoading}
            items={countriesArray}
            onChange={(_: EventType, options: DropdownProps) => handleOnChange(_, options)}
            className="select-country-dropdown"
            placeholder="Select country"
            noResultsMessage="We couldn't find any matches."
          />
          <br />
          <Header as="h4" content="Current Country Codes: " style={{ margin: 0 }} />
          <ul>
            {currentCountryCodes?.map((code: string) => (
              <li>{code}</li>
            ))}
          </ul>
        </>
      )}
    </Box>
  );
};

export default Step1;
