import { Flex, Loader } from '@fluentui/react-northstar';
import { useAppContext } from 'contexts/AppContext';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import DirectRoutingSetup from './DirectRoutingSetup';
import OperatorConnectSetup from './OperatorConnectSetup';

const Setup = () => {
  const { tenantData } = useSelector((state: RootState) => state.auth);
  const { isTenantLoading } = useAppContext();
  const history = useHistory();

  if (!tenantData) {
    if (isTenantLoading) {
      return (
        <Flex hAlign="center" vAlign="center" style={{ height: '100vh' }}>
          <Loader />
        </Flex>
      );
    }
    return (
      <Flex hAlign="center" vAlign="center" style={{ height: '100vh' }}>
        <h4>Failed to load tenant information</h4>
      </Flex>
    );
  }

  if (tenantData.status === 2) {
    history.replace('/tab');
    return null;
  }

  return tenantData?.serviceType === 'OperatorConnect' ? <OperatorConnectSetup /> : <DirectRoutingSetup />;
};

export default Setup;
