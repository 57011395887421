import React, { useCallback, useContext, useState } from 'react';
import { Flex, Input, InputProps, Toolbar } from '@fluentui/react-northstar';
import { SearchIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import debounce from 'lodash/debounce';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import ActionPopup from './components/ActionPopup';
import FilterPopup from './components/FilterPopup';

import Context from '../context';

import { actionToolbarStyles, outlineStyles, toolbarStyles } from './ManageToolbar.styles';

const ManageToolbar = (): React.ReactElement => {
  const { currentPage, searchKey, setSearchKey, setCurrentPage } = useContext(Context);
  const [action, setAction] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const handleKeywordChange = (e: React.SyntheticEvent<HTMLElement, Event>, data?: InputProps & { value: string }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    const value = data?.value ?? '';

    setSearchBoxValue(value);
    debounceValue(value);
  };

  const handleKeywordReset = () => {
    setSearchBoxValue('');
    setSearchKey('');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceValue = useCallback(debounce(setSearchKey, 500), []);

  const { isMobile } = useIsMobileView();

  return (
    <Flex gap="gap.small" style={actionToolbarStyles} className="toolbar">
      <Input
        icon={
          !searchKey ? <SearchIcon outline /> : <CloseIcon outline style={outlineStyles} onClick={handleKeywordReset} />
        }
        placeholder="Search..."
        name="SearchBox"
        onChange={handleKeywordChange}
        value={searchBoxValue}
        className="toolbar--search"
      />
      <FilterPopup />
      {!isMobile && (
        <Flex.Item push>
          <div>
            <Toolbar
              aria-label="Default"
              items={[
                {
                  icon: <ActionPopup />,
                  key: 'action',
                  kind: 'toggle',
                  title: 'Action',
                  active: action,
                  onClick: () => setAction((prev) => !prev),
                },
              ]}
              style={toolbarStyles}
            />
          </div>
        </Flex.Item>
      )}
    </Flex>
  );
};

export default ManageToolbar;
