const getMsalRedirectUri = () => {
  try {
    const url = new URL(window?.location?.href);
    return url?.origin || process.env.REACT_APP_TEAMSAPP_ID || '';
  } catch (_) {
    return process.env.REACT_APP_TEAMSAPP_ID || '';
  }
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTHORITY_URL ?? '',
    redirectUri: getMsalRedirectUri() ?? '',
    teamAppId: process.env.REACT_APP_TEAMSAPP_ID ?? '',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const graphRequest = {
  scopes: [
    '00000003-0000-0000-c000-000000000000/AppCatalog.ReadWrite.All',
    '00000003-0000-0000-c000-000000000000/Group.ReadWrite.All',
    '00000003-0000-0000-c000-000000000000/User.Read.All',
    '48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
  ],
};

export const sykpeRequest = {
  scopes: ['48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const baseUrl = process.env.REACT_APP_API_URL;

export const IS_DEBUG = process.env.REACT_APP_DEBUG === 'true';

export const BYPASS_PERMISSIONS_CHECK = IS_DEBUG;
