import { Flex, Status as FluentStatus } from '@fluentui/react-northstar';
import { StatusType } from 'types';

import { Icon } from './Icon';

const Status = ({ progress }: StatusType): React.ReactElement => {
  const { step1, step2 } = progress;

  const getColorStatus = (status: string): string => {
    switch (status) {
      case 'done':
        return 'green';
      case 'failed':
        return 'red';
      case 'ongoing':
        return 'orange';
      default:
        return 'grey';
    }
  };

  return (
    <Flex.Item grow align="center">
      <Flex column gap="gap.medium">
        <Flex vAlign="center" id="app-onboarding-tenant-whitelist">
          <FluentStatus
            color={getColorStatus(step1)}
            size="larger"
            icon={<Icon status={step1} />}
            style={{ marginRight: 5 }}
          />
          {' - Tenant whitelisting....'}
        </Flex>
        <Flex vAlign="center" id="app-onboarding-tenant-permissions">
          <FluentStatus
            color={getColorStatus(step2)}
            size="larger"
            icon={<Icon status={step2} />}
            style={{ marginRight: 5 }}
          />
          {' - API permissions....'}
        </Flex>
      </Flex>
    </Flex.Item>
  );
};

export default Status;
