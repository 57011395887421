export const INCREMENT_TIME = 5;

export const ONBOARDING_STEPS = [
  'Select Country',
  'Add FQDN',
  'Activate FQDN',
  'PSTN Setup',
  'Add Voice Routes',
  'Add Dial Plans',
];

export const ONBOARDING_STEPS_OC = ['Select Country', 'Calling Line Identity', 'Add Dial Plans'];

export const TIMEOUT_SECONDS = 600;
