import { Box, Dropdown, DropdownProps, Header } from '@fluentui/react-northstar';
import { Step1Type } from 'types';

const Step1 = ({ countriesArray, setSelectedCountry }: Step1Type): React.ReactElement => {
  type EventType = React.MouseEvent | React.KeyboardEvent | null;

  const handleOnChange = (_: EventType, options: DropdownProps) => {
    const val = options?.value as string[];
    if (!val) return;
    setSelectedCountry(val);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Header as="h3" content="Select Country" style={{ margin: 0, paddingBottom: 15 }} />
      <Dropdown
        multiple
        fluid
        items={countriesArray}
        onChange={(_: EventType, options: DropdownProps) => handleOnChange(_, options)}
        className="select-country-dropdown"
        placeholder="Select country"
        noResultsMessage="We couldn't find any matches."
      />
    </Box>
  );
};

export default Step1;
