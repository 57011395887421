import { ManageFilter, User } from 'types';

export const setFilterData = (identityData: { data: User[] }, filters: ManageFilter): User[] => {
  let data: User[] = [...identityData.data];

  const {
    status: { unmodified, pending, success, errored },
    identityType: { users, resource },
    number: { withNumber, withoutNumber },
    voiceRoute: { withVR, withoutVR },
    dialPlan: { withDP, withoutDP },
  } = filters;

  const filterByStatus = (data: User[]) => {
    let result = data.map((item: User) => {
      const { dirtyProps, status } = item;

      const isPending = pending && status === 0 && !!dirtyProps && dirtyProps?.length > 0;
      const isSuccess = success && status === 1;
      const isErrored = errored && (status === 2 || status === 3);
      const isUnmodified = unmodified && (item?.status === undefined || (dirtyProps?.length === 0 && status === 0));
      if (isPending || isSuccess || isErrored || isUnmodified) {
        return item;
      }
      return undefined;
    });
    result = result.filter((item) => !!item);
    return result as User[];
  };

  const filterByIdentityTypes = (data: User[]) => {
    let identityTypes: string[] = [];
    if (users) identityTypes = [...identityTypes, 'User'];
    if (resource) identityTypes = [...identityTypes, 'Resource'];
    return data.filter((item: User) => !item.interpretedUserType || identityTypes.includes(item.interpretedUserType));
  };

  const filterByNumber = (data: User[]) => {
    const numberProps = [withNumber, withoutNumber];
    if (numberProps.every((item) => item)) return data;
    if (withNumber) {
      data = data.filter((item) => item.onPremLineURI);
    } else {
      data = data.filter((item) => !item.onPremLineURI);
    }
    return data;
  };

  const filterByVoiceRoute = (data: User[]) => {
    const voiceRouteProps = [withVR, withoutVR];
    if (voiceRouteProps.every((item) => item)) return data;
    if (withVR) {
      data = data.filter((item) => item.onlineVoiceRoutingPolicy);
    } else {
      data = data.filter((item) => !item.onlineVoiceRoutingPolicy);
    }
    return data;
  };

  const filterByDialPlan = (data: User[]) => {
    const dialPlanProps = [withDP, withoutDP];
    if (dialPlanProps.every((item) => item)) return data;
    if (withDP) {
      data = data.filter((item) => item.tenantDialPlan);
    } else {
      data = data.filter((item) => !item.tenantDialPlan || item.tenantDialPlan === '');
    }
    return data;
  };

  data = filterByStatus(data);
  data = filterByIdentityTypes(data);
  data = filterByNumber(data);
  data = filterByVoiceRoute(data);
  data = filterByDialPlan(data);

  return data;
};

export const validateError = (keyword: string, { errors }: { errors?: string[] }): boolean => {
  if (!errors || !errors?.length) return true;
  const err = errors?.find((error: string) => error.includes(keyword));
  return !!err;
};
