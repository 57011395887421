import './dialog.css';

import * as React from 'react';
import {
  Alert,
  Button,
  Dialog as FluentDialog,
  DropdownItemProps,
  DropdownProps,
  Flex,
  Form as FluentForm,
  FormInput,
  FormLabel,
  Loader,
  ShorthandCollection,
} from '@fluentui/react-northstar';

import Context from '../../Manage/context';

import ManageDropdown from '../../Manage/ManageTable/components/ManageDropdown';
import { DropdownItemType, User } from '../../../types';

type DialogTypes = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (upn: User) => void;
  selectedUpn?: User;
  callerIdPolicies: ShorthandCollection<DropdownItemProps>;
  dialPlans: ShorthandCollection<DropdownItemProps>;
  numbers: ShorthandCollection<DropdownItemProps>;
  voiceRoutes: ShorthandCollection<DropdownItemProps>;
};

type UserFormData = User & {
  errors?: string[];
};

type FormTypes = Omit<DialogTypes, 'isOpen'> & {
  user?: UserFormData;
  setUpnInfo: (user?: User) => void;
  upnInfo?: User;
};

const ErrorList = (errors?: string[]) => (
  <ul>
    {errors?.map((error) => (
      <li key={error} style={{ color: '#c4314b' }}>
        {error}
      </li>
    ))}
  </ul>
);

const Form = ({
  user,
  dialPlans,
  numbers,
  setUpnInfo,
  upnInfo,
  voiceRoutes,
  onCancel,
  onConfirm,
  callerIdPolicies,
}: FormTypes) => {
  const { isSaving, isMobileSuccess, modifiedData, setModifiedData, setIsCompleted } = React.useContext(Context);
  const [isVisible, setIsVisible] = React.useState(false);
  const selectedUpn: User = { ...user };
  const {
    userPrincipalName,
    interpretedUserType,
    callerIdPolicy,
    onPremLineURI,
    onlineVoiceRoutingPolicy,
    tenantDialPlan,
    voiceLicenseStatus,
  } = selectedUpn;

  const [options, setOptions] = React.useState({
    callerIdPolicy,
    onPremLineURI,
    onlineVoiceRoutingPolicy,
    tenantDialPlan,
  });

  const onChange = (_: React.MouseEvent | React.KeyboardEvent | null, option: DropdownProps) => {
    const { placeholder, value } = option;

    setIsCompleted(false);

    let selectedUser = { ...(upnInfo ?? user) };
    selectedUser = { ...selectedUser, dirtyProps: selectedUser?.dirtyProps ?? [] };

    const labels: { [key: string]: string } = {
      Numbers: 'onPremLineURI',
      'Voice Route Policy': 'onlineVoiceRoutingPolicy',
      'Dial Plans': 'tenantDialPlan',
      'Caller ID Policy': 'callerIdPolicy',
    };

    const valueType = value as DropdownItemType;
    const property = placeholder ? labels[placeholder] : '';

    selectedUser = {
      ...selectedUser,
      [property]: valueType?.description,
    };

    if (selectedUser.dirtyProps && !selectedUser.dirtyProps.includes(property)) {
      selectedUser.dirtyProps = [...selectedUser.dirtyProps, property];
    }

    setUpnInfo(selectedUser);
    setModifiedData({ ...modifiedData, data: [selectedUser] });

    setOptions({
      ...options,
      [property]: valueType?.description,
    });
  };

  const isDropdownDisabled = isSaving || voiceLicenseStatus === 0;

  return (
    <FluentForm className="dialogForm">
      <h2>Update Identity</h2>
      {isMobileSuccess || user?.errors?.length || isVisible ? (
        <Alert
          dismissible
          onVisibleChange={() => setIsVisible(false)}
          dismissAction={{ 'aria-label': 'close' }}
          visible={isMobileSuccess || user?.errors?.length}
          success={isMobileSuccess}
          warning={!!user?.errors?.length}
          content={isMobileSuccess ? 'Successfully saved.' : ErrorList(user?.errors)}
        />
      ) : (
        ''
      )}
      <FormInput disabled label="Identity" name="indentity" value={userPrincipalName} />
      <FormInput disabled label="Identity Type" value={interpretedUserType} />
      <FormLabel content="Caller ID Policy" style={{ marginBottom: 5 }} />
      <ManageDropdown
        disabled={isDropdownDisabled}
        items={callerIdPolicies}
        onChange={onChange}
        placeholder="Caller ID Policy"
        value={options.callerIdPolicy}
      />
      <FormLabel content="Numbers" style={{ marginBottom: 5 }} />
      <ManageDropdown
        disabled={isDropdownDisabled}
        items={numbers}
        onChange={onChange}
        placeholder="Numbers"
        value={options.onPremLineURI}
      />
      <FormLabel content="Voice Route Policy" style={{ marginBottom: 5 }} />
      <ManageDropdown
        disabled={isDropdownDisabled}
        items={voiceRoutes}
        onChange={onChange}
        placeholder="Voice Route Policy"
        value={options.onlineVoiceRoutingPolicy}
      />
      <FormLabel content="Dial Plans" style={{ marginBottom: 5 }} />
      <ManageDropdown
        disabled={isDropdownDisabled || interpretedUserType === 'Resource'}
        items={dialPlans}
        onChange={onChange}
        placeholder="Dial Plans"
        value={options.tenantDialPlan}
      />
      <Flex gap="gap.smaller" className="manage-users-dialog-actions">
        <Button content="Close" disabled={isSaving} secondary onClick={onCancel} />
        <Button
          content={
            <Flex gap="gap.small">
              {isSaving && <Loader size="smallest" />}
              <p>Save</p>
            </Flex>
          }
          disabled={isSaving}
          primary
          onClick={() => {
            if (!upnInfo) return;
            onConfirm(upnInfo);
          }}
        />
      </Flex>
    </FluentForm>
  );
};

const Dialog = ({
  dialPlans,
  isOpen,
  onCancel,
  onConfirm,
  selectedUpn,
  callerIdPolicies,
  numbers,
  voiceRoutes,
}: DialogTypes): React.ReactElement => {
  const [upnInfo, setUpnInfo] = React.useState<User>();
  const dropdownProps = {
    callerIdPolicies,
    dialPlans,
    numbers,
    voiceRoutes,
    setUpnInfo,
    upnInfo,
    onCancel,
    onConfirm,
    user: selectedUpn,
  };
  return (
    <FluentDialog
      className="updateUserDialog"
      onCancel={onCancel}
      content={<Form {...dropdownProps} />}
      open={isOpen}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: 0,
      }}
    />
  );
};

export default Dialog;
