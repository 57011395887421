import { CommonStepType } from 'types';
import Content from './Content';

const Step2 = ({ progressContent, progressValue, withError }: CommonStepType): React.ReactElement => {
  const commonProps = {
    progressValue,
    withError,
  };

  return <Content header="Calling Line Identity" content={progressContent} subContent={<p></p>} {...commonProps} />;
};

export default Step2;
