var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LCH_to_sRGB, sRGB_to_LCH } from '../drafts.csswg.org/utilities';
var DEFAULT_ARGS = {
    l: 0,
    c: 0,
    h: 0,
    a: 100,
    forceinGamut: true,
    isPrecise: false,
};
function alphaToString(a) {
    if (a === void 0) { a = 100; }
    return a < 100 ? " / ".concat(a, "%") : '';
}
function isLchWithinRgb(l, c, h) {
    var rgb = LCH_to_sRGB([+l, +c, +h]);
    var ε = 0.000005;
    return rgb.reduce(function (a, b) { return a && b >= 0 - ε && b <= 1 + ε; }, true);
}
function forceIntoGamut(l, c, h, isLchWithin) {
    // Moves an lch color into the sRGB gamut
    // by holding the l and h steady,
    // and adjusting the c via binary-search
    // until the color is on the sRGB boundary.
    if (isLchWithin(l, c, h)) {
        return { l: l, c: c, h: h, isWithinLch: true };
    }
    var hiC = c;
    var loC = 0;
    var ε = 0.0001;
    c /= 2;
    // .0001 chosen fairly arbitrarily as "close enough"
    while (hiC - loC > ε) {
        if (isLchWithin(l, c, h)) {
            loC = c;
        }
        else {
            hiC = c;
        }
        c = (hiC + loC) / 2;
    }
    return { l: l, c: c, h: h, isWithinLch: false };
}
function percentToHex(percentage) {
    var decimalValue = Math.round((percentage * 255) / 100);
    var hexValue = percentage < 7 ? '0' + decimalValue.toString(16).toUpperCase() : decimalValue.toString(16).toUpperCase();
    return hexValue;
}
function calculatePercent(value, isPrecise) {
    if (isPrecise === void 0) { isPrecise = true; }
    var result = Math.round(value * 10000) / 100;
    if (isPrecise)
        return result;
    return Math.round(result);
}
function getRgbPercentageFromLch(args) {
    var request = __assign(__assign({}, DEFAULT_ARGS), args);
    var lchColor = { l: request.l, c: request.c, h: request.h };
    if (request.forceinGamut) {
        var r = forceIntoGamut(request.l, request.c, request.h, isLchWithinRgb);
        lchColor = __assign(__assign({}, lchColor), r);
    }
    var res = LCH_to_sRGB([lchColor.l, lchColor.c, lchColor.h]);
    return { value: res.map(function (c) { return calculatePercent(c, request.isPrecise); }), isWithinSRGB: lchColor.isWithinLch };
}
function checkSRGBBounds(r, g, b, a) {
    if (r < 0 || r > 1)
        throw 'Incorrect sRGB value! (r is out of [0,1] interval): ' + r;
    if (g < 0 || g > 1)
        throw 'Incorrect sRGB value! (g is out of [0,1] interval): ' + g;
    if (b < 0 || b > 1)
        throw 'Incorrect sRGB value! (b is out of [0,1] interval): ' + b;
    if (a < 0 || a > 1)
        throw 'Incorrect sRGB value! (a is out of [0,1] interval): ' + a;
}
function hexToSRGB(hex) {
    hex = hex.replace('#', '').toUpperCase();
    if (!hex || typeof hex !== 'string' || !hex.length)
        throw 'Incorrect Hex string format! (0)';
    if (!/^[A-F\d]+$/.test(hex))
        throw 'Incorrect Hex string format! (1)';
    if (hex.length < 3)
        hex = hex.padStart(6, hex);
    else if (hex.length < 6)
        hex = hex.slice(0, 3);
    else if (hex.length === 7)
        hex = hex.slice(0, 6);
    else if (hex.length > 8)
        hex = hex.slice(0, 8);
    if (hex.length === 3)
        hex += hex;
    var channels = hex.match(/.{2}/g);
    if (!channels || channels.length < 3)
        throw 'Incorrect Hex string format! (2)';
    var values = channels.map(function (x) { return parseInt(x, 16) / 255; });
    if (values.some(Number.isNaN))
        throw 'Incorrect Hex string format! (3)';
    var r = values[0], g = values[1], b = values[2], a = values[3];
    checkSRGBBounds(r, g, b, a !== null && a !== void 0 ? a : 1);
    return { r: r, g: g, b: b, a: a !== null && a !== void 0 ? a : 1 };
}
function srgbToLch(r, g, b, a) {
    if (a === void 0) { a = 1; }
    checkSRGBBounds(r, g, b, a);
    var lch = sRGB_to_LCH([r, g, b]);
    var roundedLch = lch.map(function (v) { return Math.round(v); });
    return { l: roundedLch[0], c: roundedLch[1], h: roundedLch[2], a: a };
}
export function hexToLch(hex) {
    var srgb = hexToSRGB(hex);
    return srgbToLch(srgb.r, srgb.g, srgb.b, srgb.a);
}
export function lchToHex(args) {
    var res = getRgbPercentageFromLch(args);
    var r = percentToHex(res.value[0]);
    var g = percentToHex(res.value[1]);
    var b = percentToHex(res.value[2]);
    return {
        value: "#".concat(r).concat(g).concat(b),
        alpha: args.a,
    };
}
export function lchToRgb(args) {
    var res = getRgbPercentageFromLch(args);
    var str = 'rgb(' + res.value.map(function (x) { return "".concat(x, "%"); }).join(' ') + alphaToString(args.a) + ')';
    return { value: res.value, string: str };
}
