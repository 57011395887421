import * as microsoftTeams from '@microsoft/teams-js';
import { ResourceType, loadConfiguration } from '@microsoft/teamsfx';

const teamsfxEndpoint = process.env.REACT_APP_TEAMSFX_ENDPOINT;
const startLoginPageUrl = process.env.REACT_APP_START_LOGIN_PAGE_URL;
const functionEndpoint = process.env.REACT_APP_FUNC_ENDPOINT;
const clientId = process.env.REACT_APP_CLIENT_ID;

export const PERMISSIONS = [
  '00000003-0000-0000-c000-000000000000/AppCatalog.ReadWrite.All',
  '00000003-0000-0000-c000-000000000000/Group.ReadWrite.All',
  '00000003-0000-0000-c000-000000000000/User.Read.All',
  '48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
  'User.Read',
  '00000002-0000-0000-c000-000000000000/Directory.AccessAsUser.All',
  '00000002-0000-0000-c000-000000000000/Directory.ReadWrite.All',
  '00000002-0000-0000-c000-000000000000/Domain.ReadWrite.All',
  '00000003-0000-0000-c000-000000000000/DelegatedAdminRelationship.Read.All',
];

export const initTeamsConfiguration = () => {
  loadConfiguration({
    authentication: {
      initiateLoginEndpoint: startLoginPageUrl,
      simpleAuthEndpoint: teamsfxEndpoint,
      clientId: clientId,
    },
    resources: [
      {
        type: ResourceType.API,
        name: 'default',
        properties: {
          endpoint: functionEndpoint,
        },
      },
    ],
  });
};

export const loginRequest = {
  scopes: [
    'openid',
    'User.Read',
    'AppCatalog.ReadWrite.All',
    'Directory.AccessAsUser.All',
    'Directory.ReadWrite.All',
    'Domain.ReadWrite.All',
    'email',
    'Group.ReadWrite.All',
    'profile',
    'User.Read.All',
    'https://api.interfaces.records.teams.microsoft.com/user_impersonation',
  ],
};

export const loginToTeams = async (): Promise<string> => {
  return new Promise((resolve) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (token: string) => {
        microsoftTeams.appInitialization.notifySuccess();
        resolve(token);
      },
      failureCallback: (message: string) => {
        microsoftTeams.appInitialization.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
          message,
        });
        resolve('');
      },
    });
  });
};

export const removeAppToken = (): boolean => {
  if (!window?.localStorage) return false;
  if (window.localStorage.getItem('SYMBIO_MS_TOKEN')) {
    window.localStorage.removeItem('SYMBIO_MS_TOKEN');
  }
  if (window.localStorage.getItem('SYMBIO_MS_TOKEN_EXP')) {
    window.localStorage.removeItem('SYMBIO_MS_TOKEN_EXP');
  }
  return true;
};

export const persistAppToken = (token: string) => {
  if (removeAppToken()) {
    window.localStorage.setItem('SYMBIO_MS_TOKEN', token);
    /* Set token to expire in 10 minutes. This is only checked during initial load
     * as the token will be refreshed every 5 mins once logged in */
    const exp = new Date();
    exp.setMinutes(new Date().getMinutes() + 30);
    window.localStorage.setItem('SYMBIO_MS_TOKEN_EXP', exp.getTime().toString());
  }
};

export const persistAppTenant = (tenant: string) => {
  if (window?.localStorage) {
    window.localStorage.setItem('XTENANT', tenant);
  }
};

export const removeAppTenant = () => {
  if (window?.localStorage) {
    window.localStorage.removeItem('XTENANT');
  }
};

export const persistBaseTenant = (tenant: string) => {
  if (window?.localStorage) {
    window.localStorage.setItem('BASETENANT', tenant);
  }
};

export const removeBaseTenant = () => {
  if (window?.localStorage) {
    window.localStorage.removeItem('BASETENANT');
  }
};

export const getAppToken = () => {
  if (!window?.localStorage) return undefined;
  const item = window.localStorage.getItem('SYMBIO_MS_TOKEN');
  if (!item) return undefined;
  const exp = window.localStorage.getItem('SYMBIO_MS_TOKEN_EXP');
  if (!exp) return undefined;
  if (Date.now() >= parseInt(exp, 10)) return undefined;
  return item;
};
