import { Flex, Text } from '@fluentui/react-northstar';
import { ErrorInfo } from 'types';

const ErrorInfoDisplay = (props: ErrorInfo) => {
  return (
    <Flex column hAlign="center">
      <Flex vAlign="center">
        <Text important content="Tenant: " />
        <Text style={{ marginLeft: 8 }} content={props.tenantId} />
      </Flex>
      <Flex vAlign="center">
        <Text important content="Error Code: " />
        <Text style={{ marginLeft: 8 }} content={props.errorCode} />
      </Flex>
    </Flex>
  );
};

export default ErrorInfoDisplay;
