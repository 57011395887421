export const initialFilter = {
  all: true,
  status: {
    unmodified: true,
    pending: true,
    success: true,
    errored: true,
  },
  identityType: {
    users: true,
    resource: true,
  },
  number: {
    withNumber: true,
    withoutNumber: true,
  },
  voiceRoute: {
    withVR: true,
    withoutVR: true,
  },
  dialPlan: {
    withDP: true,
    withoutDP: true,
  },
  license: {
    withLicense: true,
    withoutLicense: true,
  },
};
