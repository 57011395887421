import { Popup } from '@fluentui/react-northstar';

import Avatar from './Avatar';
import PopoverInfo from './PopoverInfo';

import { avatarWrapperStyles } from './Popover.styles';

type PopoverType = {
  contact: string;
  isSelected: boolean;
  email: string;
  status: string;
  typeLabel: string | React.ReactElement;
};

const Popover = ({ contact, email, isSelected, status, typeLabel }: PopoverType): React.ReactElement => {
  const avatarProps = {
    email,
    isSelected,
    status,
    emailDataTestId: 'user-identity',
  };

  const popoverInfoProps = {
    email,
    contact,
    typeLabel,
  };

  return (
    <Popup
      align="end"
      content={<PopoverInfo {...popoverInfoProps} />}
      position="above"
      trigger={
        <div style={avatarWrapperStyles}>
          <Avatar {...avatarProps} />
        </div>
      }
    />
  );
};

export default Popover;
