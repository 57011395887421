import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { BatchUpdateResult, RequestWithToken, User } from 'types';
import { customFetchBaseQuery } from './customFetch';

export const batchUpdateApi = createApi({
  reducerPath: 'batchUpdateApi',
  tagTypes: ['BatchUpdate'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    UpdateUsers: builder.mutation({
      query: ({ body, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'powershell/UpdateUsers',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: [{ type: 'BatchUpdate', id: 'LIST' }],
    }),
    GetUpdateUserStatus: builder.mutation<BatchUpdateResult<User>, RequestWithToken & { jobId: string | number }>({
      query: ({ jobId, token }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: `powershell/GetUpdateUserStatus?jobId=${jobId}`,
          method: 'GET',
          headers,
        };
      },
      invalidatesTags: [{ type: 'BatchUpdate', id: 'LIST' }],
    }),
  }),
});

export const { useUpdateUsersMutation, useGetUpdateUserStatusMutation } = batchUpdateApi;
