import { Box, Header } from '@fluentui/react-northstar';
import { Step6Type } from 'types';

import Content from './Content';

const SubContent = () => (
  <>
    <p>
      A dial plan allows users to dial a local number without the complete area code; long story short if you have a
      number in New South Wales "02 9999 9999" Dial plans make it so you can call that number without adding "02" from
      New South Wales.
    </p>
    <p>Please be patient; there is a lot of tasks we have to run to add Dial Plans.</p>
  </>
);

const Step3 = ({ isCompleted, progressContent, progressValue, withError }: Step6Type): React.ReactElement => {
  const commonProps = {
    progressValue,
    withError,
  };

  return (
    <>
      {isCompleted ? (
        <Box>
          <Header as="h3" content="Setup Completed!" />
          <p>Your setup is now complete.</p>
        </Box>
      ) : (
        <Content header="Add Dial Plans" content={progressContent} subContent={<SubContent />} {...commonProps} />
      )}
    </>
  );
};

export default Step3;
