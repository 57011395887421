import { useEffect, useState } from 'react';
import { Alert } from '@fluentui/react-northstar';

import { deepRefresh } from 'helpers/utils';
import { ReloadType, SetupErrorBoxType } from 'types';

const Reload = ({ timeLeft }: ReloadType) => {
  return (
    <p>
      Please contact support. Page will reload in {timeLeft} second/s... Click{' '}
      <span
        onClick={deepRefresh}
        style={{
          fontWeight: 600,
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        here
      </span>
      {' to reload page.'}
    </p>
  );
};

const ErrorBox = ({ errorMessage, isNotVerified, timeLeft, withError }: SetupErrorBoxType): React.ReactElement => {
  const [errorContent, setErrorContent] = useState<string | JSX.Element>('');

  useEffect(() => {
    let content: string | JSX.Element = '';

    if (isNotVerified) {
      content = 'Please follow the instruction provided.';
    }

    if (withError) {
      content = `Please contact support. ${errorMessage ? errorMessage : ''}`;
    }

    if ((timeLeft ?? 0) > 0) {
      content = <Reload timeLeft={timeLeft} />;
    }

    setErrorContent(content);
  }, [errorMessage, isNotVerified, timeLeft, withError]);

  return <>{errorContent && <Alert info content={errorContent} style={{ margin: '0 0 20px' }} />}</>;
};

export default ErrorBox;
