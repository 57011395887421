import { CommonStepType } from 'types';
import Content from './Content';

const Step4 = ({ progressContent, progressValue, withError }: CommonStepType): React.ReactElement => {
  const commonProps = {
    progressValue,
    withError,
  };

  return (
    <Content
      header="PSTN Gateway Setup"
      content={progressContent}
      subContent={
        <p>
          Setting up the PSTN in your Microsoft Teams requires a bit of work. This is typically completed with
          PowerShell scripts however our setup will complete this work for you.
        </p>
      }
      {...commonProps}
    />
  );
};

export default Step4;
