import { useEffect, useState } from 'react';

type UseScreeSizeProps = {
  width: number;
  height: number;
};

export const useScreenSize = (): UseScreeSizeProps => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const setCurrentDimensions = () => {
    const { innerHeight, innerWidth } = window;
    setDimensions({ width: Math.round(innerWidth), height: Math.round(innerHeight) });
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    setCurrentDimensions();
    window.addEventListener('resize', setCurrentDimensions);
    return () => window.removeEventListener('resize', setCurrentDimensions);
  }, []);

  return dimensions;
};
