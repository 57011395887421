import { Loader, Provider } from '@fluentui/react-northstar';
// import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
// import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';
import { AppContextProvider, useAppContext } from 'contexts/AppContext';
import AuthContextProvider from 'contexts/AuthContext';
import { FeaturesContextProvider } from 'contexts/FeaturesContext';
import { useTeamsFx } from 'hooks/useTeamsFx';
import './App.css';
import Routes from './Routes';

// const INSTRUMENTATION_KEY = process.env.REACT_APP_AI_KEY;
// const browserHistory = createBrowserHistory(window);
// const reactPlugin = new ReactPlugin();
// const appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: INSTRUMENTATION_KEY,
//     // extensions: [reactPlugin],
//     enableAutoRouteTracking: true,
//     enableAjaxPerfTracking: true,
//     enableAjaxErrorStatusText: true,
//     disableFetchTracking: false,
//     distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
//     // extensionConfig: {
//     //   [reactPlugin.identifier]: { history: browserHistory },
//     // },
//   },
// });
// appInsights.loadAppInsights();

const ThemedApp = (): React.ReactElement => {
  const { isInitialized, appTheme } = useAppContext();
  const { loading, isInTeams } = useTeamsFx();
  if (loading || !isInitialized) return <Loader style={{ margin: 100 }} />;
  const styles = isInTeams ? { padding: '.5rem 1.5rem', height: '100%' } : undefined;
  return (
    <Provider theme={appTheme} style={styles}>
      <AuthContextProvider>
        <FeaturesContextProvider>
          <Routes />
        </FeaturesContextProvider>
      </AuthContextProvider>
    </Provider>
  );
};

const App = (): React.ReactElement => (
  <AppContextProvider>
    <ThemedApp />
  </AppContextProvider>
);

export default App;
