import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Flex, Header, Image, Text } from '@fluentui/react-northstar';
import LoginImage from 'images/login/users.png';
import { AuthContext } from 'contexts/AuthContext';
import { useAppContext } from 'contexts/AppContext';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const Login = (): React.ReactElement => {
  const history = useHistory();
  const { token, tenant } = useSelector((state: RootState) => state.auth);
  const { login, error } = useContext(AuthContext);
  const { tenantTheme } = useAppContext();

  useEffect(() => {
    if (!token) return;
    history.push(!tenant ? '/partner' : '/');
  }, [history, token, tenant]);

  const APP_NAME = process.env.REACT_APP_INSTANCE_NAME ?? 'Symbio';

  return (
    <Flex
      hAlign="center"
      vAlign="center"
      style={{ height: '100vh', backgroundColor: '#f6f6f6', margin: '-0.5rem -1.5rem' }}
    >
      <Flex key="center" hAlign="center" vAlign="center" style={{ width: 350, padding: 20, textAlign: 'center' }}>
        <Box>
          <Header color="brand" as="h1" content={`Welcome to ${tenantTheme?.appName || APP_NAME}`} />
          <Text size="large" content="It's nice to see you again! Log in to continue to your account." />
          <Box style={{ maxWidth: 600 }}>
            <Image src={tenantTheme?.loginImageUrl || LoginImage} style={{ margin: '30px 0', width: '100%' }} />
          </Box>
          <Button
            content="Login"
            primary
            onClick={login}
            style={{ display: 'block', margin: 'auto', marginBottom: 24 }}
          />
          {error && <Text content={error} color="red" />}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
