import { CommonStepType } from 'types';
import Content from './Content';

const Step3 = ({ progressContent, progressValue, withError }: CommonStepType): React.ReactElement => {
  const commonProps = {
    progressValue,
    withError,
  };

  return (
    <Content
      header="Activate FQDN"
      content={progressContent}
      subContent={
        <p>
          For Microsoft to recognise the FQDNs they need to be activated in your tenant. To do this, user accounts will
          be created and licensed, that use the FQDNs. These accounts do not have any administration roles assigned and
          the licenses can be removed after your services are provisioned.
        </p>
      }
      {...commonProps}
    />
  );
};

export default Step3;
