type Props = {
  bgcolor: string;
  color: string;
  progress: number;
  height: number;
  hideLabel?: boolean;
};

const ProgressBar = ({ bgcolor, color, progress, height, hideLabel = false }: Props) => {
  return (
    <div
      style={{
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
      }}
    >
      <div
        style={{
          height: '100%',
          width: `${progress}%`,
          backgroundColor: bgcolor,
          borderRadius: 40,
          textAlign: 'right',
          transition: 'all 1s',
        }}
      >
        {!hideLabel && (
          <span
            style={{
              padding: 5,
              color: color,
              fontWeight: 900,
              display: 'block',
            }}
          >{`${progress}%`}</span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
