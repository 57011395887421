import { baseUrl } from 'authConfig';
import { getHeadersObject } from 'helpers/utils';
import { customFetch } from './customFetch';

export const getFeatures = async (token?: string): Promise<string[]> => {
  const res = await customFetch<string[]>(`${baseUrl}commondata/GetFeatures`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};
