import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export enum JobStatus {
  IN_PROGRESS = 0,
  COMPLETED = 1,
  FAILED = 2,
  COMPLETED_WITH_ERRORS = 3,
}

export enum OnboardingStep {
  NONE = 0,
  SELECT_COUNTRY = 1,
  ADD_FQDN = 2,
  ACTIVATE_FQDN = 3,
  SETUP_PSTN = 4,
  ADD_VOICE_ROUTES = 5,
  ADD_DIAL_PLANS = 6,
}

export type ApiResponse<T> = {
  message?: string;
  result?: T;
  isError?: boolean;
  responseException?: {
    exceptionMessage?: string;
  };
};

export type UpdateResult<T> = {
  id: string;
  data: T;
  errors: {
    message: string;
    errorType: string;
  }[];
  status: JobStatus;
};

export type BatchUpdateResult<T> = {
  id: string;
  results: UpdateResult<T>[];
  status: JobStatus;
  countryCodes: string[];
};

export type DropdownItemType = {
  description?: string;
  content?: React.ReactElement;
};

export type RequestWithToken = {
  token?: string;
};

export type ErrorInfo = {
  errorCode?: number;
  errorType?: string;
  tenantId?: string;
};

export type RtkErrorResponseType = { error: FetchBaseQueryError | SerializedError };

export type RtkSuccessResponseType<T> = { data: T };

export type RtkBaseResponse<T> = RtkSuccessResponseType<T> | RtkErrorResponseType;

export enum ErrorCodes {
  UNKNOWN = 10001,
  NOT_FOUND = 100002,
  NOT_AUTHORIZED = 100003,
  NOT_ALLOWED = 100004,
  NOT_IMPLEMENTED = 100005,
  NOT_SUPPORTED = 100006,
  NOT_VALID = 100007,
  NOT_READY = 100008,
  NOT_AVAILABLE = 100009,
  NOT_ENABLED = 100010,
  NOT_CONFIGURED = 100011,
  NOT_INITIALIZED = 100012,
  MISSING_TENANT = 100013,
  SERIALIZATION_ERROR = 100014,
  PS_TOKEN_ERROR = 100015,
  PS_GENERAL_ERROR = 100016,
  PS_TIMEOUT_ERROR = 100017,
  DB_GENERAL_ERROR = 100018,
  DB_TIMEOUT_ERROR = 100019,
  APP_PERMISSIONS_ERROR = 100020,
}

export enum ErrorTypes {
  UNKNOWN = 'UNKNOWN',
  NOT_FOUND = 'NOT_FOUND',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  NOT_VALID = 'NOT_VALID',
  NOT_READY = 'NOT_READY',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NOT_ENABLED = 'NOT_ENABLED',
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  MISSING_TENANT = 'MISSING_TENANT',
  SERIALIZATION_ERROR = 'SERIALIZATION_ERROR',
  PS_TOKEN_ERROR = 'PS_TOKEN_ERROR',
  PS_GENERAL_ERROR = 'PS_GENERAL_ERROR',
  PS_TIMEOUT_ERROR = 'PS_TIMEOUT_ERROR',
  DB_GENERAL_ERROR = 'DB_GENERAL_ERROR',
  DB_TIMEOUT_ERROR = 'DB_TIMEOUT_ERROR',
  APP_PERMISSIONS_ERROR = 'APP_PERMISSIONS_ERROR',
}

export type ConfigChanges = {
  dialPlans: boolean;
  voiceRoutes: boolean;
  domain: boolean;
  pstns: boolean;
};

export type ExportReportType = 'user_update' | 'user_count';

export type GenerateReportRequest = {
  hours?: number;
  reportType?: ExportReportType;
};

export type GenerateReportResponse = {
  success?: boolean;
  url?: string;
};

export type ApiErrorData = { type: string; message: string };

export class ApiError extends Error {
  data: ApiErrorData;
  status: number;
  tenantId?: string;

  constructor({ data, status }: { data: ApiErrorData; status: number }) {
    super(data?.message);
    this.data = data;
    this.status = status;
  }
}
