/* eslint-disable import/no-unresolved */
import { Dispatch, SetStateAction } from 'react';
import { Box, Card, Carousel, CarouselItemProps, ShorthandCollection, ShorthandValue } from '@fluentui/react-northstar';
import { MegaphoneIcon } from '@fluentui/react-icons-northstar';
import parse from 'html-react-parser';
import { BannerType } from 'types';
import { useAppContext } from 'contexts/AppContext';

type OnboardingCarouselType = {
  banners: BannerType[];
  adsViewedCount: number;
  setAdsViewedCount: Dispatch<SetStateAction<number>>;
};

type OnboardCarouselProps = CarouselItemProps & { id: string };

const OnboardingCarousel = ({ banners, adsViewedCount, setAdsViewedCount }: OnboardingCarouselType) => {
  const { appTheme } = useAppContext();

  if (!banners?.length) return <></>;

  const carouselItems: ShorthandCollection<OnboardCarouselProps> = banners?.map(
    ({ id, bannerTitle, bannerText }: BannerType) => {
      return {
        key: id,
        id: id,
        content: (
          <div
            style={{
              padding: '0 40px 0 185px',
              color: '#fff',
            }}
          >
            <h3>{bannerTitle}</h3>
            {parse(bannerText)}
          </div>
        ),
      };
    },
  );

  const buttonStyles: React.CSSProperties = {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 0,
    left: 0,
  };

  return (
    <Card
      style={{
        width: '100%',
        maxWidth: 900,
        minHeight: 160,
        margin: '0 auto',
        padding: '20px 10px',
        backgroundColor: appTheme?.siteVariables.colors.brand[700],
      }}
    >
      <Card.Body fitted>
        <MegaphoneIcon className="ads-icon" color="white" outline size="largest" />
        <Carousel
          aria-roledescription="carousel"
          navigation={{
            'aria-label': 'advertisement cards',
            items: carouselItems?.map((p: ShorthandValue<OnboardCarouselProps>) => ({
              key: (p as OnboardCarouselProps)?.id,
              'aria-controls': (p as OnboardCarouselProps)?.id,
            })),
          }}
          items={carouselItems}
          paddleNext={
            <Box
              style={buttonStyles}
              onClick={() => {
                setAdsViewedCount(adsViewedCount + 1);
              }}
            />
          }
          paddlePrevious={<Box style={buttonStyles} onClick={() => setAdsViewedCount(adsViewedCount - 1)} />}
          getItemPositionText={(index, size) => `${index + 1} of ${size}`}
        />
      </Card.Body>
    </Card>
  );
};

export default OnboardingCarousel;
