export const allCheckboxStyles = {
  margin: '3px 0',
};

export const buttonStyles = {
  marginTop: 5,
  marginLeft: -12,
  border: 'none',
  boxShadow: 'none',
  borderRadius: 0,
};

export const checkboxStyles = {
  margin: '3px 0',
  display: 'grid',
};

export const dividerStyles = {
  margin: 0,
};

export const headerStyles = {
  margin: '8px 0',
};

export const formStyles = {
  margin: 0,
  overflow: 'auto',
  height: 405,
};

export const wrapperStyles = {
  borderLeft: '1px solid #edebe9',
  paddingLeft: 10,
};
