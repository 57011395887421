/* eslint-disable @typescript-eslint/no-explicit-any */
import { CallIcon, CheckmarkCircleIcon, ExclamationCircleIcon, UndoIcon } from '@fluentui/react-icons-northstar';
import { Button, Flex, FlexItem, Label, MenuButton, Popup } from '@fluentui/react-northstar';
import React, { useContext } from 'react';

import ManageDropdown from './ManageDropdown';
import Popover from './Popover';

import {
  setCallerIdOptions,
  transformToDialPlanOptions,
  transformToDropdownOptions,
  upsertData,
} from '../../../../helpers/utils';
import { validateError } from '../utils';

import Context from '../../context';

import { modifiedDataBG, modifiedDataColor } from '../constants';

import { useAppContext } from 'contexts/AppContext';
import { errorTextStyles } from './TableRow.styles';

type userPrincipalNameType = {
  userPrincipalName: string;
};

const TableRow = (
  identities: any,
  tableData: any,
  theme: string | undefined,
  identityData: any,
  setFilteredNumbers: any,
  filteredNumbers: any,
  voiceRoutePolicies: any,
  dialPlans: any,
  setIdentityData: React.Dispatch<any>,
  callerIds: any,
  serviceType: string | undefined,
) => {
  const { isSaving, modifiedData, setModifiedData, setIsCompleted } = useContext(Context);
  const { appTheme } = useAppContext();

  const setRowErrors = (mergedData: any): string[] => {
    const { onPremLineURI, onlineVoiceRoutingPolicy, tenantDialPlan, interpretedUserType } = mergedData;
    const errors = [];
    if (!onPremLineURI) {
      errors.push('No assigned number');
    }
    if (!onlineVoiceRoutingPolicy && serviceType !== 'OperatorConnect') {
      errors.push('No assigned voice route policy');
    }
    if (!tenantDialPlan && interpretedUserType !== 'Resource') {
      errors.push('No assigned tenant dial plan');
    }
    return errors;
  };

  const handleDropdownChange = (event: any, option: any, identityId: string, field: string) => {
    const filteredData = identityData?.data.find((item: any) => item.userPrincipalName === identityId);
    const dirtyProps = filteredData?.dirtyProps ?? [];
    const isNumberCleared = field === 'onPremLineURI' && !option.value?.description;

    if (field === 'onPremLineURI') {
      const spreadNumbers = filteredNumbers?.map((item: any) => {
        const itemNumber = item?.number.replace('+', '');
        const incomingNumber = option?.value?.description.replace('+', '');
        const currentNumber = filteredData?.onPremLineURI.replace('+', '');

        let isAssigned = false;

        if (itemNumber === (incomingNumber || currentNumber)) {
          isAssigned = !isNumberCleared;
        } else if (itemNumber === currentNumber && itemNumber !== incomingNumber) {
          isAssigned = false;
        } else {
          isAssigned = item?.isAssigned;
        }

        return {
          ...item,
          isAssigned,
        };
      });

      setFilteredNumbers(spreadNumbers);
    }

    const mergedData = {
      ...filteredData,
      [field]: option.value?.description || '',
      dirtyProps: [...dirtyProps, field]?.filter((v: any, i: any, a: any) => a.indexOf(v) === i),
    };
    const errors = setRowErrors(mergedData);
    mergedData.errors = errors;
    const allowedError = mergedData?.interpretedUserType !== 'Resource' ? 3 : 2;
    mergedData.status = errors.length === 0 || errors.length === allowedError ? 0 : 2;

    upsertData(modifiedData.data, mergedData);
    setModifiedData({ data: modifiedData.data });
    setIsCompleted(false);
  };

  const handleUndo = (username: string) => {
    const { data: initialData } = identities;
    const { data: tableData } = identityData;
    const { data: dirtyData } = modifiedData;

    const data = [...tableData];

    const filteredData = dirtyData.filter(
      ({ userPrincipalName }: userPrincipalNameType) => userPrincipalName !== username,
    );
    const index = tableData.findIndex(({ userPrincipalName }: userPrincipalNameType) => userPrincipalName === username);
    const { onPremLineURI, tenantDialPlan, onlineVoiceRoutingPolicy, callerIdPolicy } = dirtyData.find(
      ({ userPrincipalName }: userPrincipalNameType) => userPrincipalName === username,
    );

    const spreadNumbers = filteredNumbers?.map((item: any) => {
      const itemNumber = item?.number.replace('+', '');
      const incomingNumber = initialData[index].onPremLineURI?.replace('+', '');
      const currentNumber = onPremLineURI?.replace('+', '');

      let isAssigned = item.isAssigned;

      if (itemNumber === currentNumber) {
        isAssigned = false;
      }

      if (itemNumber === incomingNumber) {
        isAssigned = true;
      }

      return {
        ...item,
        isAssigned,
      };
    });

    setFilteredNumbers(spreadNumbers);

    data[index] = {
      ...data[index],
      onPremLineURI: validateError('OnPremLineURI', data[index]) ? initialData[index].onPremLineURI : onPremLineURI,
      tenantDialPlan: validateError('TenantDialPlan', data[index]) ? initialData[index].tenantDialPlan : tenantDialPlan,
      onlineVoiceRoutingPolicy: validateError('OnlineVoiceRoutingPolicy', data[index])
        ? initialData[index].onlineVoiceRoutingPolicy
        : onlineVoiceRoutingPolicy,
      callerIdPolicy: validateError('callerIdPolicy', data[index]) ? initialData[index].callerIdPolicy : callerIdPolicy,
      status: 0,
      errors: [],
      dirtyProps: [],
    };

    setIdentityData({ ...identityData, data });
    setModifiedData({ data: filteredData });
  };

  const isModifiedData = (username: string) =>
    modifiedData.data.find(({ userPrincipalName }: userPrincipalNameType) => userPrincipalName === username);

  const getStatus = (username: string) => {
    const userInfo = modifiedData?.data?.find(
      ({ userPrincipalName }: userPrincipalNameType) => userPrincipalName === username,
    );
    return userInfo?.status ?? 0;
  };

  return tableData?.data?.map((details: any, index: number) => {
    const {
      errors,
      callerIdPolicy,
      interpretedUserType,
      onlineVoiceRoutingPolicy,
      onPremLineURI,
      status,
      tenantDialPlan,
      userPrincipalName,
      voiceLicenseStatus,
    } = details || {};

    const weightValue = isModifiedData(userPrincipalName) ? 700 : 400;

    const currentTheme = theme ?? 'default';

    const validateValue = (value?: string) => (value ? value : null);

    const rowStyles = isModifiedData(userPrincipalName)
      ? {
          background: modifiedDataBG[currentTheme][getStatus(userPrincipalName)],
          color: modifiedDataColor[currentTheme][getStatus(userPrincipalName)],
        }
      : null;

    const ErrorList = ({ errors }: { errors: any }) => (
      <ul>
        {errors.map((error: any) => (
          <li key={error} style={errorTextStyles}>
            {error}
          </li>
        ))}
      </ul>
    );

    type NumberType = {
      id: string;
      isAssigned: boolean;
      name: string;
      number: string;
      status: number;
    };

    const getFilteredNumbers = () => {
      let selectedNumbers: any = [];

      identityData?.data?.forEach((item: any) => {
        if (item.onPremLineURI) {
          selectedNumbers = [...selectedNumbers, item.onPremLineURI];
        }
      });

      modifiedData?.data?.forEach((item: any) => {
        if (item.onPremLineURI) {
          selectedNumbers = [...selectedNumbers, item.onPremLineURI];
        }
      });

      const numbers = filteredNumbers?.filter(
        (item: NumberType) => !selectedNumbers.includes(item.number) && !item.isAssigned,
      );

      const sortedNumbers = numbers?.sort(
        (a: NumberType, b: NumberType) => parseFloat(a.number) - parseFloat(b.number),
      );

      return sortedNumbers;
    };

    const isDropdownDisabled = isSaving || voiceLicenseStatus === 0;

    return {
      key: index,
      className: `users-table--row status-${status}`,
      'data-testid': userPrincipalName,
      items: [
        {
          content: (
            <div>
              <Flex gap="gap.small">
                {[2, 3].some((id: number) => id === status) && (
                  <Popup
                    content={<ErrorList errors={errors} />}
                    on="hover"
                    position="after"
                    align="bottom"
                    trigger={<ExclamationCircleIcon />}
                  />
                )}
                {status === 1 && <CheckmarkCircleIcon className="" />}
                <FlexItem>
                  <Popover
                    contact={onPremLineURI}
                    email={userPrincipalName}
                    isSelected={isModifiedData(userPrincipalName)}
                    status="active"
                    typeLabel={<Label circular color="brand" content={interpretedUserType} />}
                  />
                </FlexItem>
              </Flex>
            </div>
          ),
          key: `${index}-0`,
        },
        {
          content: (
            <div>
              <Flex gap="gap.small">
                <Label
                  className={`interpreted-user-type ${interpretedUserType === 'User' ? 'user' : 'resource'}`}
                  circular
                  style={{
                    fontWeight: weightValue,
                    color: '#fff',
                    backgroundColor:
                      interpretedUserType === 'User'
                        ? appTheme?.siteVariables?.colorScheme?.brand?.background
                        : appTheme?.siteVariables?.colorScheme?.secondary?.background,
                  }}
                  content={interpretedUserType}
                />
                {voiceLicenseStatus !== 0 && (
                  <>
                    <FlexItem>
                      <Popup
                        content="Voice license status available"
                        on="hover"
                        position="after"
                        align="bottom"
                        trigger={<CallIcon outline className="voice-license-available" />}
                      />
                    </FlexItem>
                  </>
                )}
              </Flex>
            </div>
          ),
          key: `${index}-1`,
        },
        {
          content: (
            <ManageDropdown
              id="callerId-dropdown"
              className="caller-id"
              disabled={isDropdownDisabled}
              items={setCallerIdOptions(callerIds)}
              onChange={(e: any, options: any) => handleDropdownChange(e, options, userPrincipalName, 'callerIdPolicy')}
              placeholder="Caller ID Policy"
              value={validateValue(callerIdPolicy)}
            />
          ),
          key: `${index}-2`,
        },
        {
          content: (
            <ManageDropdown
              className="on-prem-line-uri"
              disabled={isDropdownDisabled}
              items={transformToDropdownOptions(getFilteredNumbers())}
              onChange={(e: any, options: any) => handleDropdownChange(e, options, userPrincipalName, 'onPremLineURI')}
              placeholder="Numbers"
              value={validateValue(onPremLineURI)}
            />
          ),
          key: `${index}-3`,
        },
        {
          content: (
            <ManageDropdown
              className="voice-route-policies"
              disabled={isDropdownDisabled || serviceType === 'OperatorConnect'}
              items={transformToDropdownOptions(voiceRoutePolicies)}
              onChange={(e: any, options: any) =>
                handleDropdownChange(e, options, userPrincipalName, 'onlineVoiceRoutingPolicy')
              }
              placeholder="Voice Route Policies"
              value={validateValue(onlineVoiceRoutingPolicy)}
            />
          ),
          key: `${index}-4`,
        },
        {
          content: (
            <ManageDropdown
              className="tenant-dial-plans"
              disabled={isDropdownDisabled || interpretedUserType === 'Resource'}
              items={transformToDialPlanOptions(dialPlans)}
              onChange={(e: any, options: any) => handleDropdownChange(e, options, userPrincipalName, 'tenantDialPlan')}
              placeholder="Dial Plans"
              value={validateValue(tenantDialPlan)}
            />
          ),
          key: `${index}-5`,
        },
        {
          content: isModifiedData(userPrincipalName) && status !== 1 && (
            <Button
              disabled={isSaving}
              icon={<UndoIcon />}
              iconOnly
              size="small"
              title="undo"
              onClick={() => handleUndo(userPrincipalName)}
              data-testid="btn-undo"
            />
          ),
          key: `${index}-6`,
        },
      ],
      style: rowStyles,
      children: (Component: React.FC, { key, ...rest }: any) => (
        <MenuButton key={key} contextMenu trigger={<Component {...rest} />} />
      ),
    };
  });
};

export default TableRow;
