import { AccountInfo } from '@azure/msal-browser';
import { createSlice } from '@reduxjs/toolkit';
import { TenantData } from 'types';

type AuthStateProps = {
  token?: string;
  tenant?: string;
  accountInfo?: AccountInfo;
  tenantData?: TenantData;
};

const INIT_STATE: AuthStateProps = {
  token: '',
  tenant: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE as AuthStateProps,
  reducers: {
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAccountInfo: (state, action) => {
      state.accountInfo = action.payload;
    },
    clearSession: (state) => {
      state = INIT_STATE;
    },
    setTenantData: (state, action) => {
      state.tenantData = action.payload;
    },
  },
});

export const { setTenant, setToken, setAccountInfo, clearSession } = authSlice.actions;
