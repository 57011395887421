import { Table as FluentTable, Button } from '@fluentui/react-northstar';
import { EditIcon } from '@fluentui/react-icons-northstar';

import { CallerId, UpnType, TableType } from 'types';
import { HEADER } from '../../constants';

const Table = ({
  callerIds,
  callerIdsWithAccount,
  setIsDetailsOpen,
  setSelectedCallerId,
}: TableType): React.ReactElement => {
  const handleItemClick = (item?: CallerId) => {
    setSelectedCallerId(item);
    setIsDetailsOpen(true);
  };

  const setRowsValue = () =>
    callerIdsWithAccount?.map((e: CallerId, i: number) => {
      return {
        key: i,
        items: [
          {
            content: <span data-caller-id-policy={e.callerIdPolicy}>{e.callerIdPolicy}</span>,
            key: `1-${i}`,
          },
          {
            content: <span data-company-name={e.companyName}>{e.companyName}</span>,
            key: `2-${i}`,
          },
          {
            content: (e.upn as UpnType)?.userPrincipalName,
            key: `3-${i}`,
          },
          {
            content: (
              <Button
                className="btn-row-edit"
                icon={<EditIcon />}
                onClick={() => handleItemClick(callerIds?.data?.[0])}
                title="Update"
                iconOnly
              />
            ),
            key: `4-${i}`,
          },
        ],
        onClick: () => handleItemClick(e),
      };
    });

  return (
    <FluentTable
      variables={{
        cellContentOverflow: 'none',
      }}
      header={HEADER}
      rows={setRowsValue()}
      className="table-cli-list"
      aria-label="Nested navigation"
    />
  );
};

export default Table;
