import { useState, useEffect } from 'react';

const MOBILE_BREAKPOINT = 768;

export const useIsMobileView = (): { isMobile: boolean } => {
  const [isMobile, setIsMobile] = useState(false);

  const updateMobileView = () => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
  };

  useEffect(() => {
    updateMobileView();
    window.addEventListener('resize', updateMobileView);
    return () => {
      window.removeEventListener('resize', updateMobileView);
    };
  }, []);

  return { isMobile };
};
