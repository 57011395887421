import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { BatchUpdateResult, RequestWithToken, User } from 'types';
import { customFetchBaseQuery } from './customFetch';

export const importApi = createApi({
  reducerPath: 'importApi',
  tagTypes: ['GrantValue'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    ImportUsers: builder.mutation<BatchUpdateResult<User>, RequestWithToken & { file: FormData }>({
      query: ({ file, token }) => ({
        url: 'powershell/ImportUsers',
        headers: getHeaders(token),
        method: 'POST',
        body: file,
      }),
      invalidatesTags: () => [{ type: 'GrantValue', id: 'LIST' }],
    }),
  }),
});

export const { useImportUsersMutation } = importApi;
