import { useRef, useState } from 'react';
import { DownloadTenantStatusResponse } from 'types';
import {
  downloadTenantData as downloadTenantDataApi,
  getDownloadTenantStatus as getDownloadTenantStatusApi,
} from 'redux/services/tenantsApi';
import { useMutation } from './useMutation';

type DownloadTenantDataHookResponse = {
  downloadTenantData: () => Promise<void>;
  isLoading?: boolean;
};

export const useDownloadTenantData = (): DownloadTenantDataHookResponse => {
  const downloadDataCtr = useRef<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutate: getDownloadDataStatus } = useMutation<string, DownloadTenantStatusResponse>({
    callback: async (id: string): Promise<DownloadTenantStatusResponse> => {
      console.log('polling...');
      return getDownloadTenantStatusApi(id);
    },
    onSuccess: (res: DownloadTenantStatusResponse) => {
      if (!res || res.status === 0 || !res.blob) return;
      const anchor = document.createElement('a');
      const url = window.URL.createObjectURL(res.blob);
      document.body.appendChild(anchor);
      anchor.href = url;
      anchor.download = 'tenant_data.json';
      anchor.click();
      window.URL.revokeObjectURL(url);
      clearInterval(downloadDataCtr.current);
      downloadDataCtr.current = undefined;
      setIsLoading(false);
    },
    onFailure: () => {
      clearInterval(downloadDataCtr.current);
      downloadDataCtr.current = undefined;
      setIsLoading(false);
    },
  });

  const { mutate: downloadTenantData } = useMutation<void, string>({
    callback: async (): Promise<string> => {
      return downloadTenantDataApi();
    },
    onSuccess: (res: string) => {
      if (!res || !window?.setInterval) return;
      setIsLoading(true);
      const ctr = window.setInterval(() => {
        getDownloadDataStatus(res);
      }, 5000);
      downloadDataCtr.current = ctr;
    },
  });

  const handleDownloadData = async (): Promise<void> => {
    return downloadTenantData();
  };

  return { downloadTenantData: handleDownloadData, isLoading };
};
