/* eslint-disable @typescript-eslint/no-explicit-any */
type dropdownPropsType = {
  inverted: boolean;
  multiple?: boolean;
  fluid: boolean;
  noResultsMessage: string;
};

export const dropdownProps: dropdownPropsType = {
  inverted: true,
  fluid: true,
  noResultsMessage: 'No result found.',
};

type headerDetailsType = {
  key: string;
  items: {
    content: any;
    key: string;
    width?: number;
  }[];
};

export const headerDetails: headerDetailsType = {
  key: 'header',
  items: [
    {
      content: 'Identity',
      key: 'identity',
    },
    {
      content: 'Identity Type',
      key: 'identityType',
    },
    {
      content: 'Caller ID Policy',
      key: 'callerIdPolicy',
    },
    {
      content: 'Number',
      key: 'number',
    },
    {
      content: 'Voice Route Policy',
      key: 'voicePolicy',
    },
    {
      content: 'Dial Plan',
      key: 'dialPlan',
    },
    {
      content: 'Actions',
      key: 'actions',
    },
  ],
};

type modifiedDataStyleType = {
  [key: string]: any;
};

export const modifiedDataBG: modifiedDataStyleType = {
  default: {
    0: '#f4f3f1',
    1: '#e7f2da',
    2: '#fcf4f6',
    3: '#fbf6d9',
  },
  dark: '#757574',
  contrast: '#75f3ff',
};

export const modifiedDataColor: modifiedDataStyleType = {
  default: {
    0: '#000',
    1: '#237b4b',
    2: '#c4314b',
    3: '#835c00',
  },
  dark: '#fff',
  contrast: '#000',
};
